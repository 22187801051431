import React, { useState } from "react";
import {Modal, Button} from "react-bootstrap";

import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/css/custom.css";

const Information = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className="main-container st-login">
            <div className="row justify-content-center">
                <div className="col-md-10">
                    <div className="row align-items-center promo-holder mb-100">
                        <div className="col-md-5 pr-4">
                            <div className="prom-content text-right">
                                <h2 className="font-35 font-weight-bold m-0">Decentralization</h2>
                            </div>
                        </div>
                        <div className="col-md-7 pl-4 border-left">
                            <p className="mb-3 font-16">Decentralization or decentralisation is the process by which the activities of an organization, particularly those regarding planning and decision making, are distributed or delegated away from a central, authoritative location or group.</p>
                            <p className="m-0 font-16">Decentralization or decentralisation is the process by which the activities of an organization, particularly those regarding planning and decision making, are distributed or delegated away from a central, authoritative location or group.</p>

                        </div>
                    </div>
                    <div className="row align-items-center promo-holder mb-100">
                        <div className="col-md-5 pr-4">
                            <div className="prom-content text-right">
                                <h2 className="font-35 font-weight-bold  m-0">What Is A $USDC Token?</h2>
                            </div>
                        </div>
                        <div className="col-md-7 pl-4 border-left">
                            <p className="mb-0 font-16">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                        </div>
                    </div>
                    <div className="row align-items-center promo-holder mb-100">
                        <div className="col-md-5 pr-4">
                            <div className="prom-content text-right">
                                <h2 className="font-35 font-weight-bold  m-0">Algorise Marketing</h2>
                            </div>
                        </div>
                        <div className="col-md-7 pl-4 border-left">
                            <p className="mb-0 font-16">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                        </div>
                    </div>
                    <div className="row align-items-center promo-holder mb-100">
                        <div className="col-md-5 pr-4">
                            <div className="prom-content text-right">
                                <h2 className="font-35 font-weight-bold  m-0">Algorise Programs</h2>
                            </div>
                        </div>
                        <div className="col-md-7 pl-4 border-left">
                            <p className="mb-0 font-16">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                        </div>
                    </div>
                    <div className="row align-items-center promo-holder mb-100">
                        <div className="col-md-5 pr-4">
                            <div className="prom-content text-right">
                                <h2 className="font-35 font-weight-bold  m-0">Upgrade</h2>
                            </div>
                        </div>
                        <div className="col-md-7 pl-4 border-left">
                            <p className="mb-0 font-16">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                        </div>
                    </div>
                    <div className="row align-items-center promo-holder mb-100">
                        <div className="col-md-5 pr-4">
                            <div className="prom-content text-right">
                                <h2 className="font-35 font-weight-bold  m-0">Lost Profit & Extra Profit</h2>
                            </div>
                        </div>
                        <div className="col-md-7 pl-4 border-left">
                            <p className="mb-0 font-16">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                        </div>
                    </div>
                    <div className="row align-items-center promo-holder mb-100">
                        <div className="col-md-5 pr-4">
                            <div className="prom-content text-right">
                                <h2 className="font-35 font-weight-bold  m-0">Spill overs & Returns</h2>
                            </div>
                        </div>
                        <div className="col-md-7 pl-4 border-left">
                            <p className="mb-0 font-16">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                        </div>
                    </div>
                    <div className="row align-items-center promo-holder mb-100">
                        <div className="col-md-5 pr-4">
                            <div className="prom-content text-right">
                                <h2 className="font-35 font-weight-bold  m-0">Registration</h2>
                            </div>
                        </div>
                        <div className="col-md-7 pl-4 border-left">
                            <p className="mb-0 font-16">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                        </div>
                    </div>
                    <div className="row align-items-center promo-holder mb-100">
                        <div className="col-md-5 pr-4">
                            <div className="prom-content text-right">
                                <h2 className="font-35 font-weight-bold  m-0">Definition</h2>
                            </div>
                        </div>
                        <div className="col-md-7 pl-4 border-left">
                            <p className="mb-0 font-16">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                        </div>
                    </div>
                </div>
            </div>
                </div>

    )
}


export default Information;
