import React, { useState } from "react";

import Logo from "../assets/images/logo_main.png";
import {Link, useLocation} from "react-router-dom"
import {setMobileNavConnectWalletOpenStatus, setMobileNavOpenStatus} from "../redux/mobile-nav/mobile-nav-actions";
import {connect} from "react-redux";
import {Modal, Button, Nav} from "react-bootstrap";
import TopActions from "./Mobile/TopActions";
import Polygon from "../assets/images/Polygon.png";
import Doller from "../assets/images/doller.png";
import Wallet from "../assets/images/wal.png";
import User from "../assets/images/user.jpg";

const Header = ({setMobileNavOpen, isNavOpen, isConnectWalletOpen, setConnectWalletOpen}) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let location = useLocation();
    const publicRoutes = [
        '/activating', '/activating2', '/connect_wallet', '/login_approve',
    ];
    if (publicRoutes.includes(location.pathname)) {
        return null;
    }
    const toggleMobileMenu = () => {
        setMobileNavOpen(!isNavOpen);
    }
    const toggleConnectWallet = () => {
        setMobileNavConnectWalletOpenStatus(!isConnectWalletOpen);
    }
    return <div className="header fixed-top p-3">
            <div className="flex-wrap align-items-center">
                <div className="d-flex">
                    <div className="logo-wrap text-center d-flex align-items-center">
                        <a href="#">
                            <img src={Logo}/>
                        </a>
         <span className="toggle-menu ml-3" onClick={toggleMobileMenu}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
                 className="feather feather-align-left"><line x1="17" y1="10" x2="3" y2="10"></line><line x1="21" y1="6"
                                                                                                          x2="3"
                                                                                                          y2="6"></line><line
                x1="21" y1="14" x2="3" y2="14"></line><line x1="17" y1="18" x2="3" y2="18"></line></svg>
            </span>
                    </div>
                    <div className="ml-auto d-flex align-items-center mobile-actions">
                        <div className="notificationpointer">
                            <div className="d-flex align-items-center justify-content-center radius-6">
                                <i className="bi bi-bell mx-auto tr-scale-7"></i>
                            </div>
                        </div>
                        <div className="search pointer">
                            <div className="d-flex align-items-center justify-content-center radius-6">
                                <i className="bi bi-search mx-auto tr-scale-7"></i>
                            </div>
                        </div>
                        <TopActions />
                    </div>
                </div>
            </div>
        </div>

}
const mapStateToProps = state => ({
    isNavOpen: state.mobileNav.leftSidebarOpen,
    isConnectWalletOpen: state.mobileNav.connectWalletOpen
});
const mapDispatchToProps = dispatch => ({
    setMobileNavOpen: isOpen => dispatch(setMobileNavOpenStatus(isOpen)),
    setConnectWalletOpen: isOpen => dispatch(setMobileNavConnectWalletOpenStatus(isOpen)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
