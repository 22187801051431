import React from 'react';
import User from "../assets/images/user.png"
import Users from "../assets/images/users.png"
import Wallet from "../assets/images/user.png"
import Message from "../assets/images/users.png"
import Logo from "../assets/images/logo_main.png";
import Text from "../assets/images/text.svg";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/css/custom.css";
import Footer from "../Components/Footer";
const ConnectWallet = () => {
    return (
<div className="wallet">
    <div className="top-wallet ">
        <div className="container mb-4">
<div className="d-flex">
    <a href="#" className="ml-auto">
        <img src={Logo}/>
    </a>
</div>

</div>
        <div className="container">
            <div className="row align-items-center">
                <div className="col-5">
                    <p className="font-35 font-weight-bold mb-4 ml-2">Welcome To</p>
                    <div><img className="img-fluid" src={Text}/></div>
                    <p className="font-35 font-weight-bold m-0  ml-2">Experiance SplitBase</p>
                </div>
                <div className="col-4 ml-auto">
                    <div className="al-card radius-15 p-5 text-right pr-20">
                        <h1 className="font-35 font-weight-bold mb-4">Register For Geminus.io</h1>
                        <p className="mb-4">Wallet 0x1..yhdbwu is not a member of Geminus, you can use this wallet to register as a new member</p>
                        <button type="submit"
                                className="btn btn-primary btn-block py-3 w-100 radius-10 font-weight-bold">Connect Wallet
                        </button>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div className="botton-wallet">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="al-card-50 p-5 mb-5 radius-10">
                            <div className="container">
                                <div className="row mb-5">
                                    <div className="col-md-12 text-center"><h2 className="font-35 font-weight-bold  mb-3 text-light">Account
                                        Preview</h2>
                                        <p className="m-0">Look up any BINATE member account in
                                            preview mode. Enter ID or $USDC address to preview or click Demo to view a random
                                            account</p>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="account-view">
                                            <div className="row">
                                                <div className="col-md-6"> <input type="text" placeholder="Enter ID or $USDC Wallet" className="form-control al-card-50 w-100 border-0 p-3 text-light-place h-auto text-center mb-3  radius-10"/></div>
                                           <div className="col-md-6"><button type="submit" className="btn btn-primary btn-block py-3 w-100 radius-10 font-weight-bold">View Account</button></div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-md-4">
                        <div className="al-card-50 radius-10">
                            <div
                                className="card-title p-3 d-flex align-items-center justify-content-center font-15 text-light mb-3">
                                <strong>Members Total</strong>
                            </div>
                            <div className="text-center text-light">
                                <h3 className="m-0 font-w400 font-35">997 950</h3>

                            </div>
                            <div className="text-info p-3 d-flex justify-content-center align-items-center">
                                <span>+ 183 000</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="al-card-50 radius-10">
                            <div
                                className="card-title p-3 d-flex align-items-center justify-content-center font-15 text-light mb-3">
                                <strong>Turnover MATIC</strong>
                            </div>
                            <div className="text-center text-light">
                                <h3 className="m-0 font-w400 font-35">65 000 000</h3>

                            </div>
                            <div className="text-info p-3 d-flex justify-content-center align-items-center">
                                <span>+ 183 000</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="al-card-50 radius-10">
                            <div
                                className="card-title p-3 d-flex align-items-center justify-content-center font-15 text-light mb-3">
                                <strong>$USDC Contracts</strong>
                            </div>
                            <div className="text-center text-light px-5 pb-3">
                                <div className="d-flex ml-auto font-15 mb-2"><span
                                    className="text-light">1000 $USDC Package</span><span className="ml-auto d-flex w-15"><a
                                    href="#" className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="19" height="22"
                                                                   viewBox="0 0 19 22"><path id="Path_3161" data-name="Path 3161"
                                                                                             d="M16,1H4A2.006,2.006,0,0,0,2,3V17H4V3H16Zm3,4H8A2.006,2.006,0,0,0,6,7V21a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,19,5Z"
                                                                                             transform="translate(-2 -1)"
                                                                                             fill="#c1c1c1"></path></svg></a><a
                                    href="#"><svg xmlns="http://www.w3.org/2000/svg" width="18.66" height="22.321"
                                                  viewBox="0 0 18.66 22.321"><path id="Path_3169" data-name="Path 3169"
                                                                                   d="M1.9,5A3.1,3.1,0,0,1,5,1.9H9V0H5A5,5,0,0,0,5,10H9V8.1H5A3.1,3.1,0,0,1,1.9,5ZM6,6h8V4H6Zm9-6H11V1.9h4a3.1,3.1,0,1,1,0,6.2H11V10h4A5,5,0,0,0,15,0Z"
                                                                                   transform="translate(0 17.321) rotate(-60)"
                                                                                   fill="#c1c1c1"></path></svg></a></span></div>
                                <div className="d-flex ml-auto font-15 mb-2"><span
                                    className="text-light">100 $USDC Package</span><span className="ml-auto d-flex w-15"><a href="#"
                                                                                                                            className="mr-2"><svg
                                    xmlns="http://www.w3.org/2000/svg" width="19" height="22" viewBox="0 0 19 22"><path
                                    id="Path_3161" data-name="Path 3161"
                                    d="M16,1H4A2.006,2.006,0,0,0,2,3V17H4V3H16Zm3,4H8A2.006,2.006,0,0,0,6,7V21a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,19,5Z"
                                    transform="translate(-2 -1)" fill="#c1c1c1"></path></svg></a><a href="#"><svg
                                    xmlns="http://www.w3.org/2000/svg" width="18.66" height="22.321" viewBox="0 0 18.66 22.321"><path
                                    id="Path_3169" data-name="Path 3169"
                                    d="M1.9,5A3.1,3.1,0,0,1,5,1.9H9V0H5A5,5,0,0,0,5,10H9V8.1H5A3.1,3.1,0,0,1,1.9,5ZM6,6h8V4H6Zm9-6H11V1.9h4a3.1,3.1,0,1,1,0,6.2H11V10h4A5,5,0,0,0,15,0Z"
                                    transform="translate(0 17.321) rotate(-60)" fill="#c1c1c1"></path></svg></a></span></div>
                                <div className="d-flex ml-auto font-15 mb-2"><span
                                    className="text-light">100 $USDC Package</span><span className="ml-auto d-flex w-15"><a href="#"
                                                                                                                            className="mr-2"><svg
                                    xmlns="http://www.w3.org/2000/svg" width="19" height="22" viewBox="0 0 19 22"><path
                                    id="Path_3161" data-name="Path 3161"
                                    d="M16,1H4A2.006,2.006,0,0,0,2,3V17H4V3H16Zm3,4H8A2.006,2.006,0,0,0,6,7V21a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,19,5Z"
                                    transform="translate(-2 -1)" fill="#c1c1c1"></path></svg></a><a href="#"><svg
                                    xmlns="http://www.w3.org/2000/svg" width="18.66" height="22.321" viewBox="0 0 18.66 22.321"><path
                                    id="Path_3169" data-name="Path 3169"
                                    d="M1.9,5A3.1,3.1,0,0,1,5,1.9H9V0H5A5,5,0,0,0,5,10H9V8.1H5A3.1,3.1,0,0,1,1.9,5ZM6,6h8V4H6Zm9-6H11V1.9h4a3.1,3.1,0,1,1,0,6.2H11V10h4A5,5,0,0,0,15,0Z"
                                    transform="translate(0 17.321) rotate(-60)" fill="#c1c1c1"></path></svg></a></span></div>
                            </div>

                        </div>
                    </div>
                </div>
    <div className="row">
        <div className="col-lg-12 col-md-12">
                <h2 className="text-light mb-4 mt-2 pb-2 font-weight-bold font-35 text-center">Platform Recent
                    Activity</h2>
                <div className="row">
                            <div className="col-md-3">
                                <div className="al-card-50 radius-10">
                                    <div
                                        className="card-title p-3 d-flex align-items-center justify-content-center font-15 text-light mb-3">
                                        <strong>Deposits</strong></div>
                                    <div className="text-center text-light"><h3 className="m-0 font-w600 font-35">997
                                        950</h3></div>
                                    <div className="text-info p-3 d-flex justify-content-center align-items-center">
                                        <span>+ 183 000</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="al-card-50 radius-10">
                                    <div
                                        className="card-title p-3 d-flex align-items-center justify-content-center font-15 text-light mb-3">
                                        <strong>Claims</strong></div>
                                    <div className="text-center text-light"><h3 className="m-0 font-w600 font-35">997
                                        950</h3></div>
                                    <div className="text-info p-3 d-flex justify-content-center align-items-center">
                                        <span>+ 183 000</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="al-card-50 radius-10">
                                    <div
                                        className="card-title p-3 d-flex align-items-center justify-content-center font-15 text-light mb-3">
                                        <strong>Dividends</strong></div>
                                    <div className="text-center text-light"><h3 className="m-0 font-w600 font-35">997
                                        950</h3></div>
                                    <div className="text-info p-3 d-flex justify-content-center align-items-center">
                                        <span>+ 183 000</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="al-card-50 radius-10">
                                    <div
                                        className="card-title p-3 d-flex align-items-center justify-content-center font-15 text-light mb-3">
                                        <strong>Referals</strong></div>
                                    <div className="text-center text-light"><h3 className="m-0 font-w600 font-35">997
                                        950</h3></div>
                                    <div className="text-info p-3 d-flex justify-content-center align-items-center">
                                        <span>+ 183 000</span>
                                    </div>
                                </div>
                            </div>


                    <div className="col-lg-12 mt-5">
                        <div className="al-card-50 radius-10 p-4 pb-5">
                            <div className="bi-list">
                                <div
                                    className="al-list-item d-flex align-items-center justify-content-between font-15 py-3">
                                    <div className="d-flex align-items-center">
                                        <img className="mr-3" src={User}/>
                                        <p className="m-0 text-light">New user joined</p>

                                    </div>
                                    <span className="al-card-50 p-2 text-info radius-12 mx-2">ID 2398476</span>
                                    <span className="opacity-50">1 minute ago</span>
                                </div>
                                <div
                                    className="al-list-item d-flex align-items-center justify-content-between font-15 py-3">
                                    <div className="d-flex align-items-center">
                                        <img className="mr-3" src={Users}/>
                                        <p className="m-0 text-light">lorem ipsum</p>

                                    </div>
                                    <span className="al-card-50 p-2 px-3 text-info radius-12 mx-2">ID 2398476</span>
                                    <span className="opacity-50">1 minute ago</span>
                                </div>
                                <div
                                    className="al-list-item d-flex align-items-center justify-content-between font-15 py-3">
                                    <div className="d-flex align-items-center">
                                        <img className="mr-3" src={Wallet}/>
                                        <p className="m-0 text-light">lorem ipsum</p>

                                    </div>
                                    <span className="al-card-50 p-2 px-3 text-info radius-12 mx-2">ID 2398476</span>
                                    <span className="opacity-50">1 minute ago</span>
                                </div>
                                <div
                                    className="al-list-item d-flex align-items-center justify-content-between font-15 py-3">
                                    <div className="d-flex align-items-center">
                                        <img className="mr-3" src={Message}/>
                                        <p className="m-0 text-light">lorem ipsum</p>

                                    </div>
                                    <span className="al-card-50 p-2 px-3 text-info radius-12 mx-2">ID 2398476</span>
                                    <span className="opacity-50">1 minute ago</span>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
        </div>

    </div>
        </div>
    </div>
        </div>
    )
}

export default ConnectWallet;
