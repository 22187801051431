import React, {useState} from 'react';
import {Modal, Nav} from "react-bootstrap";
import Polygon from "../assets/images/Polygon.png";
import Doller from "../assets/images/doller.png";
import Wallet from "../assets/images/wal.png";
import User from "../assets/images/user.jpg";


import {Link, useLocation} from "react-router-dom";
import {connect} from "react-redux";
import {setMobileNavOpenStatus} from "../redux/mobile-nav/mobile-nav-actions";

const RightSidebar = ({isNavOpen, setMobileNavOpen})=> {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let location = useLocation();
    const publicRoutes = [
        '/login', '/activating', '/activating2', '/connect_wallet', '/login_approve',
    ];
    if(publicRoutes.includes(location.pathname)){
        return null;
    }
    const getNavLinkClass = path => {
        return location.pathname === path
            ? "active"
            : "";
    };
    const closeMobileMenu = ()=> {
        setMobileNavOpen(false);
    }
    return <div >
        <div className="right-sec d-flex flex-wrap justify-content-between align-items-center">
            <div className="top-col">
                <div className="notification mb-3 pointer">
                    <div className="al-card d-flex align-items-center justify-content-center mh-mw-72 radius-15">
                        <i className="bi bi-bell mx-auto"></i>
                    </div>
                </div>
                <div className="connect mb-3 pointer">
                    <Nav.Link  as={Link} to="/connect_wallet" className="al-card d-flex align-items-center text-center justify-content-center mh-mw-72 radius-15 bg-primary font-14 font-weight-bold text-light">
                        Go To<br/>
                        App
                    </Nav.Link>

                </div>
                <div className="search mb-3 pointer">
                    <div className="al-card d-flex align-items-center justify-content-center mh-mw-72 radius-15">
                        <i className="bi bi-search mx-auto"></i>
                    </div>
                </div>
            </div>
            <div className="middle-col">
                <div className="polygon mb-3 pointer ">
                    <div className="al-card d-flex flex-wrap align-items-center justify-content-center mh-mw-72 radius-15">
                        <img src={Polygon}/>
                        <p className="w-100 mt-2 mb-0 font-15">Polygon</p>
                    </div>
                </div>
                <div className="wallet mb-3 pointer ">
                    <div onClick={handleShow} className="al-card d-flex flex-wrap align-items-center justify-content-center mh-mw-72 radius-15">
                        <img src={Wallet}/>
                        <p className="w-100 mt-2 mb-0 font-15">0xD1Ef</p>
                    </div>
                </div>
                <div className="polygon mb-3 pointer ">
                    <div className="al-card d-flex flex-wrap align-items-center justify-content-center mh-mw-72 radius-15">
                        <img src={Polygon}/>
                    </div>
                </div>
                <div className="doller pointer ">
                    <div className="al-card d-flex flex-wrap align-items-center justify-content-center mh-mw-72 radius-15">
                        <img src={Doller}/>

                    </div>
                </div>
            </div>
            <div className="bottom-col justify-content-end">
                <div className="user pointer">
                    <div className="al-card d-flex flex-wrap align-items-center justify-content-center mh-mw-72 radius-15 p-0">
                        <img className="w-100 radius-15" src={User}/>

                    </div>
                </div>
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                keyboard={false}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>

                    <div>
                        <div className="font-15 text-light mb-3"><strong>Connect Wallet </strong></div>
                        <div className="al-card bicard-light p-3 ml-auto font-15 mb-2 radius-12">
                            <div className="d-flex align-items-center"><span
                                className="text-primary mr-3">0xD1Ef...b506</span><span
                                className="ml-auto d-flex"><a href="#" className=""><svg xmlns="http://www.w3.org/2000/svg"
                                                                                         width="19"
                                                                                         height="22" viewBox="0 0 19 22"><path
                                id="Path_3161" data-name="Path 3161"
                                d="M16,1H4A2.006,2.006,0,0,0,2,3V17H4V3H16Zm3,4H8A2.006,2.006,0,0,0,6,7V21a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,19,5Z"
                                transform="translate(-2 -1)" fill="#c1c1c1"></path></svg></a></span>
                            </div>
                        </div>
                        <div className="al-card bicard-light d-flex p-3 px-3 align-items-center mb-3 radius-12">
      <span className="d-flex align-items-center text-primary">
      <img className="mr-3" src={Polygon}/> Polygon
      </span>

                        </div>
                        <div className="font-15 text-light mb-3">Current Wallet balance</div>
                        <div className="al-card bicard-light d-flex p-3 px-3 align-items-center mb-3 radius-12">
      <span className="d-flex align-items-center text-primary">
      <img className="mr-3" src={Doller}/> 40.02 USDC
      </span>

                        </div>
                        <div className="al-card bicard-light d-flex p-3 px-3 align-items-center radius-12">
      <span className="d-flex align-items-center text-primary">
      <img className="mr-3" src={Polygon}/> 0.049 MATIC
      </span>

                        </div>


                    </div>

                </Modal.Body>

            </Modal>
        </div>
    </div>
}

const mapStateToProps = state => ({
    isNavOpen: state.mobileNav.leftSidebarOpen
});
const mapDispatchToProps = dispatch => ({
    setMobileNavOpen: isOpen => dispatch(setMobileNavOpenStatus(isOpen))
});
export default connect(mapStateToProps, mapDispatchToProps)(RightSidebar);
