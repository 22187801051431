import React from 'react';
import {Nav} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import Logo from "../assets/images/logo_main.png";
import {connect} from "react-redux";
import {setMobileNavOpenStatus} from "../redux/mobile-nav/mobile-nav-actions";
import Promotion from "../Promotions/Promotions";

const LeftSidebar = ({isNavOpen, setMobileNavOpen})=> {
    let location = useLocation();
    const publicRoutes = [
        '/activating', '/activating2', '/connect_wallet', '/login_approve',
    ];
    if(publicRoutes.includes(location.pathname)){
        return null;
    }
    const getNavLinkClass = path => {
        return location.pathname === path
            ? "active"
            : "";
    };
    const closeMobileMenu = ()=> {
        setMobileNavOpen(false);
    }
    return <div><div className={`left-sidebar ${isNavOpen ? 'active': ''}`}>
        <div className="logo-wrap text-center my-5">
<img src={Logo}/>
        </div>
        <div className="left-menu">
            <Nav activeKey="home" as="ul">

                    <Nav.Item onClick={closeMobileMenu} as="li" className={getNavLinkClass("/")} >
                        <Nav.Link eventKey="home"  as={Link} to="/">
                            <i className="bi bi-dashboard"/>
                            <span>Dashboard</span>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={closeMobileMenu} as="li"  className={getNavLinkClass("/genealogy")}>
                        <Nav.Link  eventKey="Discover" as={Link} to="/genealogy">
                            <i className="bi bi-genealogy"/>
                            <span>Genealogy</span>
                        </Nav.Link>
                    </Nav.Item>
                <Nav.Item onClick={closeMobileMenu} as="li" className={getNavLinkClass("/packages")}>
                    <Nav.Link  as={Link} to="/packages">
                        <i className="bi bi-packages"/>
                        <span>Packages</span>
                    </Nav.Link>
                </Nav.Item>
                    <Nav.Item onClick={closeMobileMenu} as="li" className={getNavLinkClass("/promotions")}>
                        <Nav.Link  as={Link} to="/promotions">
                            <i className="bi bi-teams"/>
                            <span>Promotions</span>
                        </Nav.Link>
                    </Nav.Item>
                   
                   
                    <Nav.Item onClick={closeMobileMenu} as="li" className={getNavLinkClass("/information")}>
                        <Nav.Link as={Link} to="/information">
                            <i className="bi bi-info"/>
                            <span>Information</span>
                        </Nav.Link>
                    </Nav.Item>


            </Nav>
        </div>
        <div className="logout-wrap active d-flex justify-content-end my-4">
            <Nav.Link onClick={closeMobileMenu}  as={Link} to="/connect_wallet" className="d-flex text-light">
               <span className="mr-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="18.17" height="25.892" viewBox="0 0 18.17 25.892">
  <defs>
    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#51e8ef"/>
      <stop offset="1" stop-color="#09666a"/>
    </linearGradient>
  </defs>
  <g id="Group_4484" data-name="Group 4484" transform="translate(0)">
    <path id="Path_768" data-name="Path 768" d="M12.224,29.892H5.751a3.236,3.236,0,0,1-3.236-3.236V7.236A3.236,3.236,0,0,1,5.751,4h6.473V7.236H5.751V26.655h6.473Z" transform="translate(-2.514 -4)" fill="url(#linear-gradient)"/>
    <path id="Path_769" data-name="Path 769" d="M12.986,15.667l1.229-1.218-3.39-3.419h7.909a.865.865,0,0,0,0-1.73H10.808l3.443-3.414L13.033,4.657,7.5,10.139Z" transform="translate(-1.429 2.784)" fill="url(#linear-gradient)"/>
  </g>
</svg></span>
                Logout </Nav.Link>
        </div>
    </div>
    <div className="backdorp" onClick={closeMobileMenu}></div></div>

}

const mapStateToProps = state => ({
    isNavOpen: state.mobileNav.leftSidebarOpen
});
const mapDispatchToProps = dispatch => ({
    setMobileNavOpen: isOpen => dispatch(setMobileNavOpenStatus(isOpen))
});
export default connect(mapStateToProps, mapDispatchToProps)(LeftSidebar);
