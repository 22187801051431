import React, { useState } from "react";
import {Modal, Button} from "react-bootstrap";

import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/css/custom.css";
import Promo1 from "../assets/images/promo_01.jpg";
import Promo2 from "../assets/images/promo_02.jpg";
import Flag1 from "../assets/images/flag_01.png";
import Flag2 from "../assets/images/flag_02.png";
import Flag3 from "../assets/images/flag_03.png";
import Flag4 from "../assets/images/flag_04.png";
import Flag5 from "../assets/images/flag_05.png";
import Flag6 from "../assets/images/flag_06.png";
import Flag7 from "../assets/images/flag_07.png";
import Flag8 from "../assets/images/flag_08.png";
import Flag9 from "../assets/images/flag_09.png";
import Flag10 from "../assets/images/flag_10.png";
import Flag11 from "../assets/images/flag_11.png";
import Flag12 from "../assets/images/flag_12.png";

const Promotions = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className="main-container st-promotions">
          <div className="row justify-content-center">
              <div className="col-md-8">
<div className="row align-items-center promo-holder mb-100">
    <div className="col-md-4 pr-4">
        <div className="prom-content text-right">
            <h2 className="font-35 font-weight-bold mb-3">Videos</h2>
            <p className="m-0 font-18">Promo videos to share with your team members and introduce them to Geminus or to use them for your own landing pages</p>
        </div>
    </div>
    <div className="col-md-8 pl-4 border-left">
        <div className="al-card p-0 border radius-12 mb-4 position-relative">
            <div className="radius-15 img-holder">
                <img className="w-100 radius-12" src={Promo1}/>

            </div>
            <div className="transparent-bg p-3 ml-auto radius-12 font-15 w-100 position-absolute fixed-bottom">
<h3 className="font-20">We Are Geminus.io</h3>
                <div className="d-flex align-items-center">
                                <span className="text-light mr-3">
                                    <pre className="m-0 text-light opacity-75">
                                   https://youtu.be/jd736h9
                                    </pre>
                                </span>
                    <span className="ml-auto d-flex">

                              <a href="">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22" viewBox="0 0 19 22">
                                    <path id="Path_3161" data-name="Path 3161"
                                          d="M16,1H4A2.006,2.006,0,0,0,2,3V17H4V3H16Zm3,4H8A2.006,2.006,0,0,0,6,7V21a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,19,5Z"
                                          transform="translate(-2 -1)" fill="#c1c1c1"></path>
                                 </svg>
                              </a>
                           </span>
                </div>
            </div>
        </div>

        <div className="al-card p-0 border radius-12 position-relative">
            <div className="radius-15 img-holder">
                <img className="w-100 radius-12" src={Promo1}/>

            </div>
            <div className="transparent-bg p-3 ml-auto radius-12 font-15 w-100 position-absolute fixed-bottom">
                <h3 className="font-20">Geminus USDC Official Video</h3>
                <div className="d-flex align-items-center">
                                <span className="text-light mr-3">
                                    <pre className="m-0 text-light opacity-75">
                                    https://youtu.be/jd736h9
                                    </pre>
                                </span>
                    <span className="ml-auto d-flex">

                              <a href="">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22" viewBox="0 0 19 22">
                                    <path id="Path_3161" data-name="Path 3161"
                                          d="M16,1H4A2.006,2.006,0,0,0,2,3V17H4V3H16Zm3,4H8A2.006,2.006,0,0,0,6,7V21a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,19,5Z"
                                          transform="translate(-2 -1)" fill="#c1c1c1"></path>
                                 </svg>
                              </a>
                           </span>
                </div>
            </div>
        </div>
    </div>
</div>
 <div className="row align-items-center promo-holder mb-100">
                      <div className="col-md-4 pr-4">
                          <div className="prom-content text-right">
                              <h2 className="font-35 font-weight-bold mb-3">Banners</h2>
                              <p className="m-0 font-18">One of the most effective way to promote your link online, just copy the banner code and insert it on your website or in your marketing campaign</p>
                          </div>
                      </div>
                      <div className="col-md-8 pl-4 border-left">
                          <div className="al-card p-0 border radius-12 mb-4 position-relative">
                              <div className="radius-15 img-holder">
                                  <img className="w-100 radius-12" src={Promo2}/>

                              </div>
                              <div className="transparent-bg p-3 ml-auto radius-12 font-15 w-100 position-absolute fixed-bottom">

                                  <div className="d-flex align-items-center">
                                <span className="text-light mr-3">
                                    <pre className="m-0 text-light opacity-75">
                                    https://youtu.be/jd736h9
                                    </pre>
                                </span>
                                      <span className="ml-auto d-flex">

                              <a href="">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22" viewBox="0 0 19 22">
                                    <path id="Path_3161" data-name="Path 3161"
                                          d="M16,1H4A2.006,2.006,0,0,0,2,3V17H4V3H16Zm3,4H8A2.006,2.006,0,0,0,6,7V21a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,19,5Z"
                                          transform="translate(-2 -1)" fill="#c1c1c1"></path>
                                 </svg>
                              </a>
                           </span>
                                  </div>
                              </div>
                          </div>

                          <div className="al-card p-0 border radius-12 position-relative">
                              <div className="radius-15 img-holder">
                                  <img className="w-100 radius-12" src={Promo2}/>

                              </div>
                              <div className="transparent-bg p-3 ml-auto radius-12 font-15 w-100 position-absolute fixed-bottom">
                                  <div className="d-flex align-items-center">
                                <span className="text-light mr-3">
                                    <pre className="m-0 text-light opacity-75">
                                    https://youtu.be/jd736h9
                                    </pre>
                                </span>
                                      <span className="ml-auto d-flex">

                              <a href="">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22" viewBox="0 0 19 22">
                                    <path id="Path_3161" data-name="Path 3161"
                                          d="M16,1H4A2.006,2.006,0,0,0,2,3V17H4V3H16Zm3,4H8A2.006,2.006,0,0,0,6,7V21a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,19,5Z"
                                          transform="translate(-2 -1)" fill="#c1c1c1"></path>
                                 </svg>
                              </a>
                           </span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
<div className="row align-items-center promo-holder mb-100">
                      <div className="col-md-4 pr-4">
                          <div className="prom-content text-right">
                              <h2 className="font-35 font-weight-bold mb-3">Decks</h2>
                              <p className="m-0 font-18">Full guide and detailed explanation of Algorise and the programs in easy-to-share presentations available in many world languages</p>
                          </div>
                      </div>
                      <div className="col-md-8 pl-5 border-left">
                          <div className="al-card border px-3 py-2 ml-auto font-15 mb-4 radius-12">
                              <div className="d-flex align-items-center">
                                  <div className="d-flex align-items-center">
                                      <img width={45} className="mr-3 rounded-circle" src={Flag1} alt="" />
                                      Algorise Presentation English.PDF
                                  </div>
                                  <span className="ml-auto d-flex">
                            <a href="" className="mr-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.056" height="22.001" viewBox="0 0 22.056 22.001">
  <g id="Group_4073" data-name="Group 4073" transform="translate(-2 -2.05)">
    <g id="Group_4074" data-name="Group 4074" transform="translate(2 2.05)">
      <path id="Path_2625" data-name="Path 2625" d="M20.043,4.493A11,11,0,0,0,14.161,2.05V4.283a8.779,8.779,0,0,1,4.312,1.791Zm1.78,7.452h2.233a11,11,0,0,0-2.443-5.882l-1.581,1.57A8.779,8.779,0,0,1,21.823,11.945Zm-1.791,6.523,1.581,1.581a11.035,11.035,0,0,0,2.443-5.882H21.823A8.784,8.784,0,0,1,20.032,18.468Zm-5.871,3.35v2.233a11,11,0,0,0,5.882-2.443l-1.581-1.581A8.7,8.7,0,0,1,14.161,21.818Zm2.863-10.326-2.863,2.852V7.523H11.95v6.821L9.087,11.48,7.528,13.05l5.528,5.528,5.528-5.528ZM11.95,21.818v2.233a11.056,11.056,0,0,1,0-22V4.283a8.835,8.835,0,0,0,0,17.534Z" transform="translate(-2 -2.05)" fill="#c1c1c1"/>
    </g>
  </g>
</svg>
                          </a>
                          <a href="">
                             <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22" viewBox="0 0 19 22">
                                <path id="Path_3161" data-name="Path 3161"
                                      d="M16,1H4A2.006,2.006,0,0,0,2,3V17H4V3H16Zm3,4H8A2.006,2.006,0,0,0,6,7V21a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,19,5Z"
                                      transform="translate(-2 -1)" fill="#c1c1c1"></path>
                             </svg>
                          </a>
                       </span>
                              </div>
                          </div>
                          <div className="al-card border px-3 py-2 ml-auto font-15 mb-4 radius-12">
                              <div className="d-flex align-items-center">
                                  <div className="d-flex align-items-center">
                                      <img width={45} className="mr-3 rounded-circle" src={Flag2} alt="" />
                                      Algorise Presentation English.pptx
                                  </div>
                                  <span className="ml-auto d-flex">
                            <a href="" className="mr-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.056" height="22.001" viewBox="0 0 22.056 22.001">
  <g id="Group_4073" data-name="Group 4073" transform="translate(-2 -2.05)">
    <g id="Group_4074" data-name="Group 4074" transform="translate(2 2.05)">
      <path id="Path_2625" data-name="Path 2625" d="M20.043,4.493A11,11,0,0,0,14.161,2.05V4.283a8.779,8.779,0,0,1,4.312,1.791Zm1.78,7.452h2.233a11,11,0,0,0-2.443-5.882l-1.581,1.57A8.779,8.779,0,0,1,21.823,11.945Zm-1.791,6.523,1.581,1.581a11.035,11.035,0,0,0,2.443-5.882H21.823A8.784,8.784,0,0,1,20.032,18.468Zm-5.871,3.35v2.233a11,11,0,0,0,5.882-2.443l-1.581-1.581A8.7,8.7,0,0,1,14.161,21.818Zm2.863-10.326-2.863,2.852V7.523H11.95v6.821L9.087,11.48,7.528,13.05l5.528,5.528,5.528-5.528ZM11.95,21.818v2.233a11.056,11.056,0,0,1,0-22V4.283a8.835,8.835,0,0,0,0,17.534Z" transform="translate(-2 -2.05)" fill="#c1c1c1"/>
    </g>
  </g>
</svg>
                          </a>
                          <a href="">
                             <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22" viewBox="0 0 19 22">
                                <path id="Path_3161" data-name="Path 3161"
                                      d="M16,1H4A2.006,2.006,0,0,0,2,3V17H4V3H16Zm3,4H8A2.006,2.006,0,0,0,6,7V21a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,19,5Z"
                                      transform="translate(-2 -1)" fill="#c1c1c1"></path>
                             </svg>
                          </a>
                       </span>
                              </div>
                          </div>
                          <div className="al-card border px-3 py-2 ml-auto font-15 mb-4 radius-12">
                              <div className="d-flex align-items-center">
                                  <div className="d-flex align-items-center">
                                      <img width={45} className="mr-3 rounded-circle" src={Flag3} alt="" />
                                      Algorise Presentation French.PDF
                                  </div>
                                  <span className="ml-auto d-flex">
                            <a href="" className="mr-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.056" height="22.001" viewBox="0 0 22.056 22.001">
  <g id="Group_4073" data-name="Group 4073" transform="translate(-2 -2.05)">
    <g id="Group_4074" data-name="Group 4074" transform="translate(2 2.05)">
      <path id="Path_2625" data-name="Path 2625" d="M20.043,4.493A11,11,0,0,0,14.161,2.05V4.283a8.779,8.779,0,0,1,4.312,1.791Zm1.78,7.452h2.233a11,11,0,0,0-2.443-5.882l-1.581,1.57A8.779,8.779,0,0,1,21.823,11.945Zm-1.791,6.523,1.581,1.581a11.035,11.035,0,0,0,2.443-5.882H21.823A8.784,8.784,0,0,1,20.032,18.468Zm-5.871,3.35v2.233a11,11,0,0,0,5.882-2.443l-1.581-1.581A8.7,8.7,0,0,1,14.161,21.818Zm2.863-10.326-2.863,2.852V7.523H11.95v6.821L9.087,11.48,7.528,13.05l5.528,5.528,5.528-5.528ZM11.95,21.818v2.233a11.056,11.056,0,0,1,0-22V4.283a8.835,8.835,0,0,0,0,17.534Z" transform="translate(-2 -2.05)" fill="#c1c1c1"/>
    </g>
  </g>
</svg>
                          </a>
                          <a href="">
                             <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22" viewBox="0 0 19 22">
                                <path id="Path_3161" data-name="Path 3161"
                                      d="M16,1H4A2.006,2.006,0,0,0,2,3V17H4V3H16Zm3,4H8A2.006,2.006,0,0,0,6,7V21a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,19,5Z"
                                      transform="translate(-2 -1)" fill="#c1c1c1"></path>
                             </svg>
                          </a>
                       </span>
                              </div>
                          </div>
                          <div className="al-card border px-3 py-2 ml-auto font-15 mb-4 radius-12">
                              <div className="d-flex align-items-center">
                                  <div className="d-flex align-items-center">
                                      <img width={45} className="mr-3 rounded-circle" src={Flag4} alt="" />
                                      Algorise Presentation Indonesian.PDF
                                  </div>
                                  <span className="ml-auto d-flex">
                            <a href="" className="mr-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.056" height="22.001" viewBox="0 0 22.056 22.001">
  <g id="Group_4073" data-name="Group 4073" transform="translate(-2 -2.05)">
    <g id="Group_4074" data-name="Group 4074" transform="translate(2 2.05)">
      <path id="Path_2625" data-name="Path 2625" d="M20.043,4.493A11,11,0,0,0,14.161,2.05V4.283a8.779,8.779,0,0,1,4.312,1.791Zm1.78,7.452h2.233a11,11,0,0,0-2.443-5.882l-1.581,1.57A8.779,8.779,0,0,1,21.823,11.945Zm-1.791,6.523,1.581,1.581a11.035,11.035,0,0,0,2.443-5.882H21.823A8.784,8.784,0,0,1,20.032,18.468Zm-5.871,3.35v2.233a11,11,0,0,0,5.882-2.443l-1.581-1.581A8.7,8.7,0,0,1,14.161,21.818Zm2.863-10.326-2.863,2.852V7.523H11.95v6.821L9.087,11.48,7.528,13.05l5.528,5.528,5.528-5.528ZM11.95,21.818v2.233a11.056,11.056,0,0,1,0-22V4.283a8.835,8.835,0,0,0,0,17.534Z" transform="translate(-2 -2.05)" fill="#c1c1c1"/>
    </g>
  </g>
</svg>
                          </a>
                          <a href="">
                             <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22" viewBox="0 0 19 22">
                                <path id="Path_3161" data-name="Path 3161"
                                      d="M16,1H4A2.006,2.006,0,0,0,2,3V17H4V3H16Zm3,4H8A2.006,2.006,0,0,0,6,7V21a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,19,5Z"
                                      transform="translate(-2 -1)" fill="#c1c1c1"></path>
                             </svg>
                          </a>
                       </span>
                              </div>
                          </div>
                          <div className="al-card border px-3 py-2 ml-auto font-15 mb-4 radius-12">
                              <div className="d-flex align-items-center">
                                  <div className="d-flex align-items-center">
                                      <img width={45} className="mr-3 rounded-circle" src={Flag5} alt="" />
                                      Algorise Presentation Japanese.PDF
                                  </div>
                                  <span className="ml-auto d-flex">
                            <a href="" className="mr-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.056" height="22.001" viewBox="0 0 22.056 22.001">
  <g id="Group_4073" data-name="Group 4073" transform="translate(-2 -2.05)">
    <g id="Group_4074" data-name="Group 4074" transform="translate(2 2.05)">
      <path id="Path_2625" data-name="Path 2625" d="M20.043,4.493A11,11,0,0,0,14.161,2.05V4.283a8.779,8.779,0,0,1,4.312,1.791Zm1.78,7.452h2.233a11,11,0,0,0-2.443-5.882l-1.581,1.57A8.779,8.779,0,0,1,21.823,11.945Zm-1.791,6.523,1.581,1.581a11.035,11.035,0,0,0,2.443-5.882H21.823A8.784,8.784,0,0,1,20.032,18.468Zm-5.871,3.35v2.233a11,11,0,0,0,5.882-2.443l-1.581-1.581A8.7,8.7,0,0,1,14.161,21.818Zm2.863-10.326-2.863,2.852V7.523H11.95v6.821L9.087,11.48,7.528,13.05l5.528,5.528,5.528-5.528ZM11.95,21.818v2.233a11.056,11.056,0,0,1,0-22V4.283a8.835,8.835,0,0,0,0,17.534Z" transform="translate(-2 -2.05)" fill="#c1c1c1"/>
    </g>
  </g>
</svg>
                          </a>
                          <a href="">
                             <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22" viewBox="0 0 19 22">
                                <path id="Path_3161" data-name="Path 3161"
                                      d="M16,1H4A2.006,2.006,0,0,0,2,3V17H4V3H16Zm3,4H8A2.006,2.006,0,0,0,6,7V21a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,19,5Z"
                                      transform="translate(-2 -1)" fill="#c1c1c1"></path>
                             </svg>
                          </a>
                       </span>
                              </div>
                          </div>
                          <div className="al-card border px-3 py-2 ml-auto font-15 mb-4 radius-12">
                              <div className="d-flex align-items-center">
                                  <div className="d-flex align-items-center">
                                      <img width={45} className="mr-3 rounded-circle" src={Flag6} alt="" />
                                      Algorise Presentation Philippines.PDF
                                  </div>
                                  <span className="ml-auto d-flex">
                            <a href="" className="mr-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.056" height="22.001" viewBox="0 0 22.056 22.001">
  <g id="Group_4073" data-name="Group 4073" transform="translate(-2 -2.05)">
    <g id="Group_4074" data-name="Group 4074" transform="translate(2 2.05)">
      <path id="Path_2625" data-name="Path 2625" d="M20.043,4.493A11,11,0,0,0,14.161,2.05V4.283a8.779,8.779,0,0,1,4.312,1.791Zm1.78,7.452h2.233a11,11,0,0,0-2.443-5.882l-1.581,1.57A8.779,8.779,0,0,1,21.823,11.945Zm-1.791,6.523,1.581,1.581a11.035,11.035,0,0,0,2.443-5.882H21.823A8.784,8.784,0,0,1,20.032,18.468Zm-5.871,3.35v2.233a11,11,0,0,0,5.882-2.443l-1.581-1.581A8.7,8.7,0,0,1,14.161,21.818Zm2.863-10.326-2.863,2.852V7.523H11.95v6.821L9.087,11.48,7.528,13.05l5.528,5.528,5.528-5.528ZM11.95,21.818v2.233a11.056,11.056,0,0,1,0-22V4.283a8.835,8.835,0,0,0,0,17.534Z" transform="translate(-2 -2.05)" fill="#c1c1c1"/>
    </g>
  </g>
</svg>
                          </a>
                          <a href="">
                             <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22" viewBox="0 0 19 22">
                                <path id="Path_3161" data-name="Path 3161"
                                      d="M16,1H4A2.006,2.006,0,0,0,2,3V17H4V3H16Zm3,4H8A2.006,2.006,0,0,0,6,7V21a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,19,5Z"
                                      transform="translate(-2 -1)" fill="#c1c1c1"></path>
                             </svg>
                          </a>
                       </span>
                              </div>
                          </div>
                          <div className="al-card border px-3 py-2 ml-auto font-15 mb-4 radius-12">
                              <div className="d-flex align-items-center">
                                  <div className="d-flex align-items-center">
                                      <img width={45} className="mr-3 rounded-circle" src={Flag7} alt="" />
                                      Algorise Presentation Russian.PDF
                                  </div>
                                  <span className="ml-auto d-flex">
                            <a href="" className="mr-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.056" height="22.001" viewBox="0 0 22.056 22.001">
  <g id="Group_4073" data-name="Group 4073" transform="translate(-2 -2.05)">
    <g id="Group_4074" data-name="Group 4074" transform="translate(2 2.05)">
      <path id="Path_2625" data-name="Path 2625" d="M20.043,4.493A11,11,0,0,0,14.161,2.05V4.283a8.779,8.779,0,0,1,4.312,1.791Zm1.78,7.452h2.233a11,11,0,0,0-2.443-5.882l-1.581,1.57A8.779,8.779,0,0,1,21.823,11.945Zm-1.791,6.523,1.581,1.581a11.035,11.035,0,0,0,2.443-5.882H21.823A8.784,8.784,0,0,1,20.032,18.468Zm-5.871,3.35v2.233a11,11,0,0,0,5.882-2.443l-1.581-1.581A8.7,8.7,0,0,1,14.161,21.818Zm2.863-10.326-2.863,2.852V7.523H11.95v6.821L9.087,11.48,7.528,13.05l5.528,5.528,5.528-5.528ZM11.95,21.818v2.233a11.056,11.056,0,0,1,0-22V4.283a8.835,8.835,0,0,0,0,17.534Z" transform="translate(-2 -2.05)" fill="#c1c1c1"/>
    </g>
  </g>
</svg>
                          </a>
                          <a href="">
                             <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22" viewBox="0 0 19 22">
                                <path id="Path_3161" data-name="Path 3161"
                                      d="M16,1H4A2.006,2.006,0,0,0,2,3V17H4V3H16Zm3,4H8A2.006,2.006,0,0,0,6,7V21a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,19,5Z"
                                      transform="translate(-2 -1)" fill="#c1c1c1"></path>
                             </svg>
                          </a>
                       </span>
                              </div>
                          </div>
                          <div className="al-card border px-3 py-2 ml-auto font-15 mb-4 radius-12">
                              <div className="d-flex align-items-center">
                                  <div className="d-flex align-items-center">
                                      <img width={45} className="mr-3 rounded-circle" src={Flag8} alt="" />
                                      Algorise Presentation Tamil.PDF
                                  </div>
                                  <span className="ml-auto d-flex">
                            <a href="" className="mr-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.056" height="22.001" viewBox="0 0 22.056 22.001">
  <g id="Group_4073" data-name="Group 4073" transform="translate(-2 -2.05)">
    <g id="Group_4074" data-name="Group 4074" transform="translate(2 2.05)">
      <path id="Path_2625" data-name="Path 2625" d="M20.043,4.493A11,11,0,0,0,14.161,2.05V4.283a8.779,8.779,0,0,1,4.312,1.791Zm1.78,7.452h2.233a11,11,0,0,0-2.443-5.882l-1.581,1.57A8.779,8.779,0,0,1,21.823,11.945Zm-1.791,6.523,1.581,1.581a11.035,11.035,0,0,0,2.443-5.882H21.823A8.784,8.784,0,0,1,20.032,18.468Zm-5.871,3.35v2.233a11,11,0,0,0,5.882-2.443l-1.581-1.581A8.7,8.7,0,0,1,14.161,21.818Zm2.863-10.326-2.863,2.852V7.523H11.95v6.821L9.087,11.48,7.528,13.05l5.528,5.528,5.528-5.528ZM11.95,21.818v2.233a11.056,11.056,0,0,1,0-22V4.283a8.835,8.835,0,0,0,0,17.534Z" transform="translate(-2 -2.05)" fill="#c1c1c1"/>
    </g>
  </g>
</svg>
                          </a>
                          <a href="">
                             <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22" viewBox="0 0 19 22">
                                <path id="Path_3161" data-name="Path 3161"
                                      d="M16,1H4A2.006,2.006,0,0,0,2,3V17H4V3H16Zm3,4H8A2.006,2.006,0,0,0,6,7V21a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,19,5Z"
                                      transform="translate(-2 -1)" fill="#c1c1c1"></path>
                             </svg>
                          </a>
                       </span>
                              </div>
                          </div>
                          <div className="al-card border px-3 py-2 ml-auto font-15 mb-4 radius-12">
                              <div className="d-flex align-items-center">
                                  <div className="d-flex align-items-center">
                                      <img width={45} className="mr-3 rounded-circle" src={Flag9} alt="" />
                                      Algorise Presentation Vietnamese.PDF
                                  </div>
                                  <span className="ml-auto d-flex">
                            <a href="" className="mr-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.056" height="22.001" viewBox="0 0 22.056 22.001">
  <g id="Group_4073" data-name="Group 4073" transform="translate(-2 -2.05)">
    <g id="Group_4074" data-name="Group 4074" transform="translate(2 2.05)">
      <path id="Path_2625" data-name="Path 2625" d="M20.043,4.493A11,11,0,0,0,14.161,2.05V4.283a8.779,8.779,0,0,1,4.312,1.791Zm1.78,7.452h2.233a11,11,0,0,0-2.443-5.882l-1.581,1.57A8.779,8.779,0,0,1,21.823,11.945Zm-1.791,6.523,1.581,1.581a11.035,11.035,0,0,0,2.443-5.882H21.823A8.784,8.784,0,0,1,20.032,18.468Zm-5.871,3.35v2.233a11,11,0,0,0,5.882-2.443l-1.581-1.581A8.7,8.7,0,0,1,14.161,21.818Zm2.863-10.326-2.863,2.852V7.523H11.95v6.821L9.087,11.48,7.528,13.05l5.528,5.528,5.528-5.528ZM11.95,21.818v2.233a11.056,11.056,0,0,1,0-22V4.283a8.835,8.835,0,0,0,0,17.534Z" transform="translate(-2 -2.05)" fill="#c1c1c1"/>
    </g>
  </g>
</svg>
                          </a>
                          <a href="">
                             <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22" viewBox="0 0 19 22">
                                <path id="Path_3161" data-name="Path 3161"
                                      d="M16,1H4A2.006,2.006,0,0,0,2,3V17H4V3H16Zm3,4H8A2.006,2.006,0,0,0,6,7V21a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,19,5Z"
                                      transform="translate(-2 -1)" fill="#c1c1c1"></path>
                             </svg>
                          </a>
                       </span>
                              </div>
                          </div>
                          <div className="al-card border px-3 py-2 ml-auto font-15 mb-4 radius-12">
                              <div className="d-flex align-items-center">
                                  <div className="d-flex align-items-center">
                                      <img width={45} className="mr-3 rounded-circle" src={Flag10} alt="" />
                                      Algorise Presentation Mandarin.PDF
                                  </div>
                                  <span className="ml-auto d-flex">
                            <a href="" className="mr-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.056" height="22.001" viewBox="0 0 22.056 22.001">
  <g id="Group_4073" data-name="Group 4073" transform="translate(-2 -2.05)">
    <g id="Group_4074" data-name="Group 4074" transform="translate(2 2.05)">
      <path id="Path_2625" data-name="Path 2625" d="M20.043,4.493A11,11,0,0,0,14.161,2.05V4.283a8.779,8.779,0,0,1,4.312,1.791Zm1.78,7.452h2.233a11,11,0,0,0-2.443-5.882l-1.581,1.57A8.779,8.779,0,0,1,21.823,11.945Zm-1.791,6.523,1.581,1.581a11.035,11.035,0,0,0,2.443-5.882H21.823A8.784,8.784,0,0,1,20.032,18.468Zm-5.871,3.35v2.233a11,11,0,0,0,5.882-2.443l-1.581-1.581A8.7,8.7,0,0,1,14.161,21.818Zm2.863-10.326-2.863,2.852V7.523H11.95v6.821L9.087,11.48,7.528,13.05l5.528,5.528,5.528-5.528ZM11.95,21.818v2.233a11.056,11.056,0,0,1,0-22V4.283a8.835,8.835,0,0,0,0,17.534Z" transform="translate(-2 -2.05)" fill="#c1c1c1"/>
    </g>
  </g>
</svg>
                          </a>
                          <a href="">
                             <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22" viewBox="0 0 19 22">
                                <path id="Path_3161" data-name="Path 3161"
                                      d="M16,1H4A2.006,2.006,0,0,0,2,3V17H4V3H16Zm3,4H8A2.006,2.006,0,0,0,6,7V21a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,19,5Z"
                                      transform="translate(-2 -1)" fill="#c1c1c1"></path>
                             </svg>
                          </a>
                       </span>
                              </div>
                          </div>
                          <div className="al-card border px-3 py-2 ml-auto font-15 mb-4 radius-12">
                              <div className="d-flex align-items-center">
                                  <div className="d-flex align-items-center">
                                      <img width={45} className="mr-3 rounded-circle" src={Flag11} alt="" />
                                      Algorise Presentation Hindi.PDF
                                  </div>
                                  <span className="ml-auto d-flex">
                            <a href="" className="mr-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.056" height="22.001" viewBox="0 0 22.056 22.001">
  <g id="Group_4073" data-name="Group 4073" transform="translate(-2 -2.05)">
    <g id="Group_4074" data-name="Group 4074" transform="translate(2 2.05)">
      <path id="Path_2625" data-name="Path 2625" d="M20.043,4.493A11,11,0,0,0,14.161,2.05V4.283a8.779,8.779,0,0,1,4.312,1.791Zm1.78,7.452h2.233a11,11,0,0,0-2.443-5.882l-1.581,1.57A8.779,8.779,0,0,1,21.823,11.945Zm-1.791,6.523,1.581,1.581a11.035,11.035,0,0,0,2.443-5.882H21.823A8.784,8.784,0,0,1,20.032,18.468Zm-5.871,3.35v2.233a11,11,0,0,0,5.882-2.443l-1.581-1.581A8.7,8.7,0,0,1,14.161,21.818Zm2.863-10.326-2.863,2.852V7.523H11.95v6.821L9.087,11.48,7.528,13.05l5.528,5.528,5.528-5.528ZM11.95,21.818v2.233a11.056,11.056,0,0,1,0-22V4.283a8.835,8.835,0,0,0,0,17.534Z" transform="translate(-2 -2.05)" fill="#c1c1c1"/>
    </g>
  </g>
</svg>
                          </a>
                          <a href="">
                             <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22" viewBox="0 0 19 22">
                                <path id="Path_3161" data-name="Path 3161"
                                      d="M16,1H4A2.006,2.006,0,0,0,2,3V17H4V3H16Zm3,4H8A2.006,2.006,0,0,0,6,7V21a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,19,5Z"
                                      transform="translate(-2 -1)" fill="#c1c1c1"></path>
                             </svg>
                          </a>
                       </span>
                              </div>
                          </div>

                      </div>
                  </div>
              </div>
          </div>

        </div>
    )
}


export default Promotions;
