import React from 'react';

import { Container, Row} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/css/custom.css";
import Footer from "../Components/Footer";
const Genealogy = () => {
    return (
<div className="main-container bottom-light right-light st-genealogy">
        <div className="container-fluid">
            <div className="row mb-100 genealogy-top">
                <div className="col-4 pl-5">
                    <div className="rating-col">
                       <div className="d-flex mb-5 pb-3 position-relative">
                           <div className="line-gen" style={{left:"42%", width:"110%"}}></div>
                           <div className="al-card py-0 px-2 border radius-8 bg-transparent d-flex justify-content-center">
                        <span className="mr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="37.684" height="35.8" viewBox="0 0 37.684 35.8">
<defs><linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox"><stop offset="0" stop-color="#fcb415"/><stop offset="1" stop-color="#e8810d"/></linearGradient>
</defs>
<path id="Path_3185" data-name="Path 3185" d="M20.842,30.772,32.486,37.8,29.4,24.554l10.288-8.912L26.137,14.492,20.842,2,15.547,14.492,2,15.642l10.288,8.912L9.2,37.8Z" transform="translate(-2 -2)" fill="url(#linear-gradient)"/>
</svg>
                            </span>
                               <span className="mr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="37.684" height="35.8" viewBox="0 0 37.684 35.8">
<defs><linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox"><stop offset="0" stop-color="#fcb415"/><stop offset="1" stop-color="#e8810d"/></linearGradient>
</defs>
<path id="Path_3185" data-name="Path 3185" d="M20.842,30.772,32.486,37.8,29.4,24.554l10.288-8.912L26.137,14.492,20.842,2,15.547,14.492,2,15.642l10.288,8.912L9.2,37.8Z" transform="translate(-2 -2)" fill="url(#linear-gradient)"/>
</svg>
                            </span>
                               <span className="mr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="37.684" height="35.8" viewBox="0 0 37.684 35.8">
<defs><linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox"><stop offset="0" stop-color="#fcb415"/><stop offset="1" stop-color="#e8810d"/></linearGradient>
</defs>
<path id="Path_3185" data-name="Path 3185" d="M20.842,30.772,32.486,37.8,29.4,24.554l10.288-8.912L26.137,14.492,20.842,2,15.547,14.492,2,15.642l10.288,8.912L9.2,37.8Z" transform="translate(-2 -2)" fill="url(#linear-gradient)"/>
</svg>
                            </span>
                               <span className="mr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="37.684" height="35.8" viewBox="0 0 37.684 35.8">
<defs><linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox"><stop offset="0" stop-color="#fcb415"/><stop offset="1" stop-color="#e8810d"/></linearGradient>
</defs>
<path id="Path_3185" data-name="Path 3185" d="M20.842,30.772,32.486,37.8,29.4,24.554l10.288-8.912L26.137,14.492,20.842,2,15.547,14.492,2,15.642l10.288,8.912L9.2,37.8Z" transform="translate(-2 -2)" fill="url(#linear-gradient)"/>
</svg>
                            </span>
                               <span className="">
                        <svg xmlns="http://www.w3.org/2000/svg" width="37.684" height="35.8" viewBox="0 0 37.684 35.8">
<path id="Path_3185" data-name="Path 3185" d="M20.842,30.772,32.486,37.8,29.4,24.554l10.288-8.912L26.137,14.492,20.842,2,15.547,14.492,2,15.642l10.288,8.912L9.2,37.8Z" transform="translate(-2 -2)" fill="#818E94"/>
</svg>
                            </span>
                           </div>
                       </div>
                        <div className="d-flex mb-3 position-relative">
                            <div className="line-gen" style={{left:"42%"}}></div>
                            <div className="al-card py-0 px-2 border radius-8 bg-transparent d-flex justify-content-center">
                        <span className="mr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="37.684" height="35.8" viewBox="0 0 37.684 35.8">
<defs><linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox"><stop offset="0" stop-color="#fcb415"/><stop offset="1" stop-color="#e8810d"/></linearGradient>
</defs>
<path id="Path_3185" data-name="Path 3185" d="M20.842,30.772,32.486,37.8,29.4,24.554l10.288-8.912L26.137,14.492,20.842,2,15.547,14.492,2,15.642l10.288,8.912L9.2,37.8Z" transform="translate(-2 -2)" fill="url(#linear-gradient)"/>
</svg>
                            </span>
                                <span className="mr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="37.684" height="35.8" viewBox="0 0 37.684 35.8">
<defs><linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox"><stop offset="0" stop-color="#fcb415"/><stop offset="1" stop-color="#e8810d"/></linearGradient>
</defs>
<path id="Path_3185" data-name="Path 3185" d="M20.842,30.772,32.486,37.8,29.4,24.554l10.288-8.912L26.137,14.492,20.842,2,15.547,14.492,2,15.642l10.288,8.912L9.2,37.8Z" transform="translate(-2 -2)" fill="url(#linear-gradient)"/>
</svg>
                            </span>
                                <span className="mr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="37.684" height="35.8" viewBox="0 0 37.684 35.8">
<defs><linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox"><stop offset="0" stop-color="#fcb415"/><stop offset="1" stop-color="#e8810d"/></linearGradient>
</defs>
<path id="Path_3185" data-name="Path 3185" d="M20.842,30.772,32.486,37.8,29.4,24.554l10.288-8.912L26.137,14.492,20.842,2,15.547,14.492,2,15.642l10.288,8.912L9.2,37.8Z" transform="translate(-2 -2)" fill="url(#linear-gradient)"/>
</svg>
                            </span>
                                <span className="mr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="37.684" height="35.8" viewBox="0 0 37.684 35.8">
<defs><linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox"><stop offset="0" stop-color="#fcb415"/><stop offset="1" stop-color="#e8810d"/></linearGradient>
</defs>
<path id="Path_3185" data-name="Path 3185" d="M20.842,30.772,32.486,37.8,29.4,24.554l10.288-8.912L26.137,14.492,20.842,2,15.547,14.492,2,15.642l10.288,8.912L9.2,37.8Z" transform="translate(-2 -2)" fill="url(#linear-gradient)"/>
</svg>
                            </span>
                                <span className="">
                        <svg xmlns="http://www.w3.org/2000/svg" width="37.684" height="35.8" viewBox="0 0 37.684 35.8">
<path id="Path_3185" data-name="Path 3185" d="M20.842,30.772,32.486,37.8,29.4,24.554l10.288-8.912L26.137,14.492,20.842,2,15.547,14.492,2,15.642l10.288,8.912L9.2,37.8Z" transform="translate(-2 -2)" fill="#818E94"/>
</svg>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-8">
                    <div className="table-responsive">
            <div className="tree">
                <ul>
                    <li><a className="main-gen" href="#">You</a>
                        <ul>
                            <li><a href="#">A</a>
                                <ul>
                                    <li><a href="#">A<sup>1</sup></a></li>
                                    <li><a href="#">A<sup>2</sup></a></li>
                                    <li><a href="#">A<sup>3</sup></a></li>
                                </ul>
                            </li>
                            <li><a href="#">B</a>
                                <ul>
                                    <li><a href="#">B<sup>1</sup></a></li>
                                    <li><a href="#">B<sup>2</sup></a></li>
                                    <li><a href="#">B<sup>3</sup></a></li>
                                </ul>
                            </li>
                            <li><a href="#">C</a>
                                <ul>
                                    <li><a href="#">C<sup>1</sup></a></li>
                                    <li><a href="#">C<sup>2</sup></a></li>
                                    <li><a href="#">C<sup>3</sup></a></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
                </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-11 genealogy">
                    <div className="row justify-content-center">
                        <div className="col-md-3">
                            <div className="al-card-bg radius-15">
                                <div
                                    className="card-title p-3 d-flex align-items-center justify-content-center font-15 text-light mb-3">
                                    <strong>Ranking</strong>
                                </div>
                                <div className="text-center text-light pb-3">
                                    <div className=" d-flex justify-content-center">
                        <span className="mr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="37.684" height="35.8" viewBox="0 0 37.684 35.8">
<defs><linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox"><stop offset="0" stop-color="#fcb415"/><stop offset="1" stop-color="#e8810d"/></linearGradient>
</defs>
<path id="Path_3185" data-name="Path 3185" d="M20.842,30.772,32.486,37.8,29.4,24.554l10.288-8.912L26.137,14.492,20.842,2,15.547,14.492,2,15.642l10.288,8.912L9.2,37.8Z" transform="translate(-2 -2)" fill="url(#linear-gradient)"/>
</svg>
                            </span>
                                        <span className="mr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="37.684" height="35.8" viewBox="0 0 37.684 35.8">
<defs><linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox"><stop offset="0" stop-color="#fcb415"/><stop offset="1" stop-color="#e8810d"/></linearGradient>
</defs>
<path id="Path_3185" data-name="Path 3185" d="M20.842,30.772,32.486,37.8,29.4,24.554l10.288-8.912L26.137,14.492,20.842,2,15.547,14.492,2,15.642l10.288,8.912L9.2,37.8Z" transform="translate(-2 -2)" fill="url(#linear-gradient)"/>
</svg>
                            </span>
                                        <span className="mr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="37.684" height="35.8" viewBox="0 0 37.684 35.8">
<defs><linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox"><stop offset="0" stop-color="#fcb415"/><stop offset="1" stop-color="#e8810d"/></linearGradient>
</defs>
<path id="Path_3185" data-name="Path 3185" d="M20.842,30.772,32.486,37.8,29.4,24.554l10.288-8.912L26.137,14.492,20.842,2,15.547,14.492,2,15.642l10.288,8.912L9.2,37.8Z" transform="translate(-2 -2)" fill="url(#linear-gradient)"/>
</svg>
                            </span>
                                        <span className="mr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="37.684" height="35.8" viewBox="0 0 37.684 35.8">
<defs><linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox"><stop offset="0" stop-color="#fcb415"/><stop offset="1" stop-color="#e8810d"/></linearGradient>
</defs>
<path id="Path_3185" data-name="Path 3185" d="M20.842,30.772,32.486,37.8,29.4,24.554l10.288-8.912L26.137,14.492,20.842,2,15.547,14.492,2,15.642l10.288,8.912L9.2,37.8Z" transform="translate(-2 -2)" fill="url(#linear-gradient)"/>
</svg>
                            </span>
                                        <span className="">
                        <svg xmlns="http://www.w3.org/2000/svg" width="37.684" height="35.8" viewBox="0 0 37.684 35.8">
<path id="Path_3185" data-name="Path 3185" d="M20.842,30.772,32.486,37.8,29.4,24.554l10.288-8.912L26.137,14.492,20.842,2,15.547,14.492,2,15.642l10.288,8.912L9.2,37.8Z" transform="translate(-2 -2)" fill="#818E94"/>
</svg>
                            </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-3">
                            <div className="al-card-bg radius-15">
                                <div
                                    className="card-title p-3 d-flex align-items-center justify-content-center font-15 text-light mb-3">
                                    <strong>Total Sales</strong>
                                </div>
                                <div className="text-center text-light pb-3">
                                    <h3 className="m-0 font-weight-bold font-30">0 $USDC</h3>

                                </div>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="al-card-bg p-4 radius-15  d-flex  position-relative ">
                                <div className="text-center position-relative z-index2">

                                    <div className="d-flex  font-15 mb-3">
                                        <span className="text-light font-weight-bold mr-2">Direct Referal</span>
                                        <span className="ml-auto text-primary">
                                            7 People
                                        </span>
                                    </div>
                                    <div className="d-flex  font-15 mb-3">
                                        <span className="text-light font-weight-bold mr-2">Unlocked Level</span>
                                        <span className="ml-auto text-primary">
                                           Level 7
                                        </span>
                                    </div>
                                    <div className="d-flex  font-15 mb-3">
                                        <span className="text-light font-weight-bold mr-2">Total Team</span>
                                        <span className="ml-auto text-primary">
                                            237
                                        </span>
                                    </div>
                                    <div className="d-flex  font-15 mb-3">
                                        <span className="text-light font-weight-bold mr-2">Current Month Sales</span>
                                        <span className="ml-auto text-primary">
                                           $16000
                                        </span>
                                    </div>
                                    <div className="d-flex  font-15 mb-3">
                                        <span className="text-light font-weight-bold mr-2">Total Sales</span>
                                        <span className="ml-auto text-primary">
                                           $391600
                                        </span>
                                    </div>
                                    <div className="d-flex  font-15">
                                        <span className="text-light font-weight-bold mr-2">Total Commission</span>
                                        <span className="ml-auto text-primary">
                                          $295700
                                        </span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </div>
    )
}

export default Genealogy;
