import {MobileNavActionTypes} from "./mobile-nav-types";

const INITIAL_STATE = {
    leftSidebarOpen: false,
    connectWalletOpen: false
};
const mobileNavReducer = (state = INITIAL_STATE, action) =>{
    switch (action.type){
        case MobileNavActionTypes.SET_MOBILE_NAV_OPEN:
            return {
                ...state,
                leftSidebarOpen: action.payload
            }
        case MobileNavActionTypes.SET_MOBILE_NAV_CONNECT_WALLET_OPEN:
            return {
                ...state,
                connectWalletOpen: action.payload
            }
        default:
            return state;
    }
}
export default mobileNavReducer;
