import React, {useState} from "react";
import PolygonIcon from "../../assets/images/Polygon.svg"
import DollarIcon from "../../assets/images/doller.png"
import WalletIcon from "../../assets/images/wal.svg"
import {setMobileNavConnectWalletOpenStatus} from "../../redux/mobile-nav/mobile-nav-actions";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Modal, Nav} from "react-bootstrap";
import Polygon from "../../assets/images/Polygon.png";
import Wallet from "../../assets/images/wal.png";
import Doller from "../../assets/images/doller.png";
import User from "../../assets/images/user.jpg";

const TopActions = ({isConnectWalletOpen, setConnectWalletOpen}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [open, setOpen] = useState(false);
    const toggleConnectWallet = () => {
        setConnectWalletOpen(!isConnectWalletOpen);
    }
    return (
        <div className="">
                    <span className="d-block" onClick={()=> setOpen(!open)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24" fill="none" stroke="currentColor"
                             stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                             className="feather feather-more-vertical"><circle cx="12" cy="12"
                                                                               r="1"></circle><circle
                            cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                    </span>

            <div className={`top-header-list p-2 ${open ? 'open' : ''}`}>
                <div className="d-flex align-items-center text-center">

                        <div className="connect pointer mr-2">
                            <Nav.Link  as={Link} to="/connect_wallet" className="al-card d-flex align-items-center text-center justify-content-center mh-mw-72 radius-15 bg-primary font-14 font-weight-bold text-light">
                                Connect Wallet
                            </Nav.Link>

                        </div>

                        <div className="polygon pointer mr-2">
                            <div className="al-card d-flex align-items-center justify-content-center mh-mw-72 radius-15">
                                <img src={Polygon}/>
                                <p className="w-100 ml-2 mb-0 font-15">Polygon</p>
                            </div>
                        </div>
                        <div className="wallet pointer mr-2">
                            <div onClick={handleShow} className="al-card d-flex align-items-center justify-content-center mh-mw-72 radius-15">
                                <img src={Wallet}/>
                                <p className="w-100 ml-2 mb-0 font-15">0xD1Ef</p>
                            </div>
                        </div>
                        <div className="polygon pointer mr-2">
                            <div className="al-card d-flex flex-wrap align-items-center justify-content-center mh-mw-72 radius-15">
                                <img src={Polygon}/>
                            </div>
                        </div>
                        <div className="doller pointer mr-2">
                            <div className="al-card d-flex flex-wrap align-items-center justify-content-center mh-mw-72 radius-15">
                                <img src={Doller}/>

                            </div>
                        </div>
                        <div className="user pointer">
                            <div className="al-card d-flex flex-wrap align-items-center justify-content-center mh-mw-72 radius-15 p-0">
                                <img className="w-100 radius-15" src={User}/>

                            </div>
                        </div>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>

                            <div>
                                <div className="font-15 text-light mb-3"><strong>Connect Wallet </strong></div>
                                <div className="al-card bicard-light p-3 ml-auto font-15 mb-2 radius-12">
                                    <div className="d-flex align-items-center"><span
                                        className="text-primary mr-3">0xD1Ef...b506</span><span
                                        className="ml-auto d-flex"><a href="#" className=""><svg xmlns="http://www.w3.org/2000/svg"
                                                                                                 width="19"
                                                                                                 height="22" viewBox="0 0 19 22"><path
                                        id="Path_3161" data-name="Path 3161"
                                        d="M16,1H4A2.006,2.006,0,0,0,2,3V17H4V3H16Zm3,4H8A2.006,2.006,0,0,0,6,7V21a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,19,5Z"
                                        transform="translate(-2 -1)" fill="#c1c1c1"></path></svg></a></span>
                                    </div>
                                </div>
                                <div className="al-card bicard-light d-flex p-3 px-3 align-items-center mb-3 radius-12">
      <span className="d-flex align-items-center text-primary">
      <img className="mr-3" src={Polygon}/> Polygon
      </span>

                                </div>
                                <div className="font-15 text-light mb-3">Current Wallet balance</div>
                                <div className="al-card bicard-light d-flex p-3 px-3 align-items-center mb-3 radius-12">
      <span className="d-flex align-items-center text-primary">
      <img className="mr-3" src={Doller}/> 40.02 USDC
      </span>

                                </div>
                                <div className="al-card bicard-light d-flex p-3 px-3 align-items-center radius-12">
      <span className="d-flex align-items-center text-primary">
      <img className="mr-3" src={Polygon}/> 0.049 MATIC
      </span>

                                </div>


                            </div>

                        </Modal.Body>

                    </Modal>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = state => ({

    isConnectWalletOpen: state.mobileNav.connectWalletOpen
});
const mapDispatchToProps = dispatch => ({
    setConnectWalletOpen: isOpen => dispatch(setMobileNavConnectWalletOpenStatus(isOpen)),
});
export default connect(mapStateToProps, mapDispatchToProps) (TopActions);
