import React from 'react';

import { Container, Row} from "react-bootstrap";
import User from "../assets/images/user.png"
import Users from "../assets/images/users.png"
import Checked from "../assets/images/checked.png"
import Share from "../assets/images/share.png"
import Message from "../assets/images/message.png"
import Banner from "../assets/images/Banner_1.jpg"

import Wallet from "../assets/images/wallet.png"
import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/css/custom.css";
import Footer from "../Components/Footer";
const Dashboard = () => {
    return (
<div className="main-container al-dashboard">
        <div className="container-fluid ph-100">

            <div className="row justify-content-center mb-80">

                <div className="col-md-12 geminus">
                 <div className="banner mb-80">
                     <img className="radius-26 img-fluid" src={Banner}/>
                 </div>
                    <div className="row mb-80">
                        <div className="col-md-4">
                           <div className="al-card-bg radius-15 custon-p">
                               <h2 className="font-35 mb-3 font-weight-bold">Account Preview</h2>
                               <p className="mb-4">Look up any GEMINUS member account in preview mode. Enter ID or $USDC address to preview or click Demo to view a random account</p>
                              <div className="account-view">
                                  <input type="text" placeholder="Enter ID or $USDC Wallet" className="form-control al-card border-0 p-3 h-auto text-center mb-3  radius-10"/>
                                  <button type="submit" className="btn btn-primary btn-block py-3 radius-10 font-weight-bold">View Account</button>
                              </div>
                           </div>

                        </div>
                        <div className="col-md-5">
                            <div className="al-card-bg custon-p radius-15">
                                <h2 className="font-35 mb-3 font-weight-bold"><span className="text-primary">Geminus</span> Programs</h2>
                                <div className="d-flex">
                                    <div className="vertical-bars d-flex">
                                        <div className="deposit">
                                            <span className="btn-primary-plane" style={{height:"100%"}}></span>
                                        </div>
                                        <div className="layout ml-3">
                                            <span className="bg-layout" style={{height:"40%"}}></span>
                                        </div>
                                        <div className="allocated ml-3">
                                            <span className="bg-allocated" style={{height:"80%"}}></span>
                                        </div>
                                    </div>
                                    <div className="ml-auto">
                                <div className="d-flex justify-content-end mb-3">
                                    <span className="btn-primary-plane p-2 radius-6 wh-30">&nbsp;</span>
                                    <div className="text-right ml-3">
                                        <p className="m-0 font-25 font-weight-bold">0 $USDC</p>
                                        <small className="text-primary">Deposit</small>
                                    </div>

                                </div>
                                <div className="d-flex justify-content-end mb-3">
                                    <span className="bg-layout p-2 radius-6 wh-30">&nbsp;</span>
                                    <div className="text-right ml-3">
                                        <p className="m-0 font-25 font-weight-bold">0 $USDC</p>
                                        <small className="text-primary">Layout</small>
                                    </div>

                                </div>
                                <div class="d-flex justify-content-end mb-3">
                                    <span className="bg-allocated p-2 radius-6 wh-30">&nbsp;</span>
                                    <div className="text-right ml-3">
                                        <p className="m-0 font-25 font-weight-bold">0 $USDC</p>
                                        <small className="text-primary">Allocated</small>
                                    </div>

                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="al-card-bg custon-p radius-15">
                                <div className="row m-0">
<div className="col-auto al-card p-3 pb-5 radius-10 mr-4">
    <div className="v-slide m-0">
        <span className="v-slide-dot btn-primary radius-15" style={{top:"100%"}}>
        </span>
    </div>
</div>
                                <div className="col-auto ml-auto">
                                <div className="d-flex align-items-center justify-content-end mb-3">
                                    <div className="text-right">
                                        <p className="m-0 font-25 font-weight-bold">0 $USDC</p>
                                        <small className="text-primary">Price</small>
                                    </div>

                                </div>
                                    <div className="d-flex align-items-center justify-content-end mb-3">
                                        <div className="text-right">
                                            <p className="m-0 font-25 font-weight-bold">0</p>
                                            <small className="text-primary">Unit</small>
                                        </div>

                                    </div>
                                <div className="d-flex align-items-center justify-content-end mb-3">
                                    <div className="text-right">
                                        <p className="m-0 font-25 font-weight-bold">0 $USDC</p>
                                        <small className="text-primary">Amount</small>
                                    </div>

                                </div>

                                <div className="d-flex align-items-center justify-content-end mb-3">
                                    <div className="text-right">
                                        <a href="#" className="btn btn-primary radius-10 py-3 px-4 font-16 font-weight-bold">Buy Now</a>
                                    </div>

                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-5">
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div className="al-card-bg radius-15">
                                        <div
                                            className="card-title p-3 d-flex align-items-center justify-content-center font-15 text-light mb-3">
                                            <strong>Profits</strong>
                                        </div>
                                        <div className="text-center text-light">
                                            <h3 className="m-0 font-w600 font-35">0 $USDC</h3>

                                        </div>
                                        <div
                                            className="text-warning p-3 d-flex justify-content-center align-items-center">
                                            <span className="mr-2 text-info">+ 0 $USDC</span></div>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="al-card-bg radius-15">
                                        <div
                                            className="card-title p-3 d-flex align-items-center justify-content-center font-15 text-light mb-3">
                                            <strong>Team</strong>
                                        </div>
                                        <div className="text-center text-light">
                                            <h3 className="m-0 font-w600 font-35">0</h3>

                                        </div>
                                        <div
                                            className="text-warning p-3 d-flex justify-content-center align-items-center">
                                            <span className="mr-2 text-info">+ 0 </span></div>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="al-card-bg radius-15">
                                        <div
                                            className="card-title p-3 d-flex align-items-center justify-content-center font-15 text-light mb-3">
                                            <strong>Ratio</strong>
                                        </div>
                                        <div className="text-center text-light">
                                            <h3 className="m-0 font-w600 font-35">0%</h3>

                                        </div>
                                        <div
                                            className="text-warning p-3 d-flex justify-content-center align-items-center">
                                            <span className="mr-2 text-info">+ 0%</span></div>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="al-card-bg radius-15">
                                        <div
                                            className="card-title p-3 d-flex align-items-center justify-content-center font-15 text-light mb-3">
                                            <strong>Members Total</strong>
                                        </div>
                                        <div className="text-center text-light">
                                            <h3 className="m-0 font-w600 font-35">239 914</h3>

                                        </div>
                                        <div
                                            className="text-warning p-3 d-flex justify-content-center align-items-center">
                                            <span className="mr-2 text-info">+ 937</span></div>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="al-card-bg radius-15">
                                        <div
                                            className="card-title p-3 d-flex align-items-center justify-content-center font-15 text-light mb-3">
                                            <strong>Turnover MATIC</strong>
                                        </div>
                                        <div className="text-center text-light">
                                            <h3 className="m-0 font-w600 font-35">65 000 000</h3>

                                        </div>
                                        <div
                                            className="text-warning p-3 d-flex justify-content-center align-items-center">
                                            <span className="mr-2 text-info">+ 183 000</span></div>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="al-card-bg radius-15">
                                        <div
                                            className="card-title p-3 d-flex align-items-center justify-content-center font-15 text-light mb-3">
                                            <strong>Transactions Made</strong>
                                        </div>
                                        <div className="text-center text-light">
                                            <h3 className="m-0 font-w600 font-35">997 950</h3>

                                        </div>
                                        <div
                                            className="text-warning p-3 d-flex justify-content-center align-items-center">
                                            <span className="mr-2 text-info">+ 3895</span></div>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="al-card-bg radius-15">
                                        <div
                                            className="card-title p-3 d-flex align-items-center justify-content-center font-15 text-light mb-3">
                                            <strong>Members Received | $USDC</strong>
                                        </div>
                                        <div className="text-center text-light">
                                            <h3 className="m-0 font-w600 font-35">32 000 000</h3>

                                        </div>
                                        <div
                                            className="text-warning p-3 d-flex justify-content-center align-items-center">
                                            <span className="mr-2 text-info">+ 91000 </span></div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="al-card-bg radius-15">
                                        <div
                                            className="card-title p-3 d-flex align-items-center justify-content-center font-15 text-light mb-3">
                                            <strong>$USDC Contracts</strong>
                                        </div>
                                        <div className="p-3">
                                        <div className="d-flex font-15 mb-2"><span className="text-light">1000 $USDC Package</span><span
                                            className="ml-auto d-flex w-15"><a href="#" className="mr-2"><svg
                                            xmlns="http://www.w3.org/2000/svg" width="19" height="22"
                                            viewBox="0 0 19 22"><path id="Path_3161" data-name="Path 3161"
                                                                      d="M16,1H4A2.006,2.006,0,0,0,2,3V17H4V3H16Zm3,4H8A2.006,2.006,0,0,0,6,7V21a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,19,5Z"
                                                                      transform="translate(-2 -1)"
                                                                      fill="#c1c1c1"></path></svg></a><a href="#"><svg
                                            xmlns="http://www.w3.org/2000/svg" width="18.66" height="22.321"
                                            viewBox="0 0 18.66 22.321"><path id="Path_3169" data-name="Path 3169"
                                                                             d="M1.9,5A3.1,3.1,0,0,1,5,1.9H9V0H5A5,5,0,0,0,5,10H9V8.1H5A3.1,3.1,0,0,1,1.9,5ZM6,6h8V4H6Zm9-6H11V1.9h4a3.1,3.1,0,1,1,0,6.2H11V10h4A5,5,0,0,0,15,0Z"
                                                                             transform="translate(0 17.321) rotate(-60)"
                                                                             fill="#c1c1c1"></path></svg></a></span>
                                        </div>
                                            <div className="d-flex font-15 mb-2"><span className="text-light">1000 $USDC Package</span><span
                                                className="ml-auto d-flex w-15"><a href="#" className="mr-2"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="19" height="22"
                                                viewBox="0 0 19 22"><path id="Path_3161" data-name="Path 3161"
                                                                          d="M16,1H4A2.006,2.006,0,0,0,2,3V17H4V3H16Zm3,4H8A2.006,2.006,0,0,0,6,7V21a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,19,5Z"
                                                                          transform="translate(-2 -1)"
                                                                          fill="#c1c1c1"></path></svg></a><a href="#"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="18.66" height="22.321"
                                                viewBox="0 0 18.66 22.321"><path id="Path_3169" data-name="Path 3169"
                                                                                 d="M1.9,5A3.1,3.1,0,0,1,5,1.9H9V0H5A5,5,0,0,0,5,10H9V8.1H5A3.1,3.1,0,0,1,1.9,5ZM6,6h8V4H6Zm9-6H11V1.9h4a3.1,3.1,0,1,1,0,6.2H11V10h4A5,5,0,0,0,15,0Z"
                                                                                 transform="translate(0 17.321) rotate(-60)"
                                                                                 fill="#c1c1c1"></path></svg></a></span>
                                            </div>
                                            <div className="d-flex font-15 mb-2"><span className="text-light">1000 $USDC Package</span><span
                                                className="ml-auto d-flex w-15"><a href="#" className="mr-2"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="19" height="22"
                                                viewBox="0 0 19 22"><path id="Path_3161" data-name="Path 3161"
                                                                          d="M16,1H4A2.006,2.006,0,0,0,2,3V17H4V3H16Zm3,4H8A2.006,2.006,0,0,0,6,7V21a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,19,5Z"
                                                                          transform="translate(-2 -1)"
                                                                          fill="#c1c1c1"></path></svg></a><a href="#"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="18.66" height="22.321"
                                                viewBox="0 0 18.66 22.321"><path id="Path_3169" data-name="Path 3169"
                                                                                 d="M1.9,5A3.1,3.1,0,0,1,5,1.9H9V0H5A5,5,0,0,0,5,10H9V8.1H5A3.1,3.1,0,0,1,1.9,5ZM6,6h8V4H6Zm9-6H11V1.9h4a3.1,3.1,0,1,1,0,6.2H11V10h4A5,5,0,0,0,15,0Z"
                                                                                 transform="translate(0 17.321) rotate(-60)"
                                                                                 fill="#c1c1c1"></path></svg></a></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">

                            <div className="al-card-bg custon-p radius-15">
                                <h2 className="font-35 mb-3 font-weight-bold">Platform Recent Activity</h2>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <div className="al-card radius-15">
                                            <div
                                                className="card-title p-3 d-flex align-items-center justify-content-center font-15 text-light mb-3">
                                                <strong>Deposits</strong>
                                            </div>
                                            <div className="text-center text-light">
                                                <h3 className="m-0 font-w600 font-35">0 $USDC</h3>

                                            </div>
                                            <div
                                                className="text-warning p-3 d-flex justify-content-center align-items-center">
                                                <span className="mr-2 text-info">+ 0 $USDC</span></div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className="al-card radius-15">
                                            <div
                                                className="card-title p-3 d-flex align-items-center justify-content-center font-15 text-light mb-3">
                                                <strong>Claims</strong>
                                            </div>
                                            <div className="text-center text-light">
                                                <h3 className="m-0 font-w600 font-35">0</h3>

                                            </div>
                                            <div
                                                className="text-warning p-3 d-flex justify-content-center align-items-center">
                                                <span className="mr-2 text-info">+ 0 </span></div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className="al-card radius-15">
                                            <div
                                                className="card-title p-3 d-flex align-items-center justify-content-center font-15 text-light mb-3">
                                                <strong>Dividends</strong>
                                            </div>
                                            <div className="text-center text-light">
                                                <h3 className="m-0 font-w600 font-35">0%</h3>

                                            </div>
                                            <div
                                                className="text-warning p-3 d-flex justify-content-center align-items-center">
                                                <span className="mr-2 text-info">+ 0%</span></div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className="al-card radius-15">
                                            <div
                                                className="card-title p-3 d-flex align-items-center justify-content-center font-15 text-light mb-3">
                                                <strong>Referals</strong>
                                            </div>
                                            <div className="text-center text-light">
                                                <h3 className="m-0 font-w600 font-35">239 914</h3>

                                            </div>
                                            <div
                                                className="text-warning p-3 d-flex justify-content-center align-items-center">
                                                <span className="mr-2 text-info">+ 937</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                    <div className="al-list px-3 radius-12 px-4">
                                        <div className="al-list-item d-flex align-items-center justify-content-between font-15 py-3">
                                            <div className="d-flex align-items-center">
                                                <img className="mr-3" src={User}/>
                                                <p className="m-0 text-light">New user joined</p>
                                            </div>
                                            <span
                                                className="al-list p-2 px-3 text-primary radius-6 ml-4">ID 2398476</span>
                                            <span className="opacity-75">1 minute ago</span>
                                        </div>
                                        <div className="al-list-item d-flex align-items-center justify-content-between font-15 py-3">
                                            <div className="d-flex align-items-center">
                                                <img className="mr-3" src={Users}/>
                                                <p className="m-0 text-light">lorem ipsum</p>
                                            </div>
                                            <span
                                                className="al-list p-2 px-3 text-primary radius-6 ml-4">ID 2398476</span>
                                            <span className="opacity-75">1 minute ago</span>
                                        </div>
                                        <div className="al-list-item d-flex align-items-center justify-content-between font-15 py-3">
                                            <div className="d-flex align-items-center">
                                                <img className="mr-3" src={Wallet}/>
                                                <p className="m-0 text-light">Nlorem ipsum</p>
                                            </div>
                                            <span
                                                className="al-list p-2 px-3 text-primary radius-6 ml-4">ID 2398476</span>
                                            <span className="opacity-75">1 minute ago</span>
                                        </div>

                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </div>
        </div>
    )
}

export default Dashboard;
