import {MobileNavActionTypes} from "./mobile-nav-types";

export const setMobileNavOpenStatus = isOpen => ({
    type: MobileNavActionTypes.SET_MOBILE_NAV_OPEN,
    payload: isOpen
});

export const setMobileNavConnectWalletOpenStatus = isOpen => ({
    type: MobileNavActionTypes.SET_MOBILE_NAV_CONNECT_WALLET_OPEN,
    payload: isOpen
});
