import React from 'react';

import { Container, Row} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/css/custom.css";
import Footer from "../Components/Footer";
const Packages = () => {
    return (
<div className="main-container st-packages">
        <div className="container-fluid">

            <div className="row justify-content-center">
                <div className="col-md-4 mt-100">
                    <div className="al-card border p-4 bg-transparent radius-15 mb-4 mr-5">
                        <p className="font-30 font-weight-bold mb-0">100 $USDC</p>
                        <p className="text-info font-30 mb-0">Program</p>
                    </div>
                    <div className="al-card p-4 bg-transparent radius-15 mr-5">
                        <p className="font-30 font-weight-bold mb-0">100 $USDC</p>
                        <p className="text-info font-30 mb-0">Program</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="pck-wrap mr-3">
                                <div className="d-flex mb-n3">
                                <span className="text-light font-20 font-weight-bold al-card wh-60 d-flex align-items-center justify-content-center radius-100">2X</span>
                                    <span className="text-info font-20 al-card wh-60 d-flex align-items-center justify-content-center radius-100 ml-2">75%</span>
                                </div>
                            <div className="bi-pie-circle position-absolute"><div className="bi-pie-inner">
                                <span className="bi-pie-one bi-pie-fill"></span> <span className="bi-pie-two bi-pie-fill"></span><span className="bi-pie-three bi-pie-fill"></span><span className="bi-pie-four bi-pie-fill"></span></div></div>
                            <div className="pck-info al-card-bg radius-6 p-3 pt-4">
                                <div className="mb-3"><span className="d-block font-16 font-weight-bold">Contract ID <span className="d-block font-weight-light text-info">000001</span></span></div>
                                <div className=""><span className="d-block font-16 font-weight-bold">Date <span className="d-block font-weight-light text-info">28.01.2022</span></span></div>

                            </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="pck-wrap ml-3">
                                <div className="d-flex mb-n3">
                                    <span className="text-light font-20 font-weight-bold al-card wh-60 d-flex align-items-center justify-content-center radius-100">2X</span>
                                    <span className="text-info font-20 al-card wh-60 d-flex align-items-center justify-content-center radius-100 ml-2">75%</span>
                                </div>
                                <div className="bi-pie-circle position-absolute"><div className="bi-pie-inner">
                                    <span className="bi-pie-one bi-pie-fill"></span> <span className="bi-pie-two"></span><span className="bi-pie-three bi-pie-fill"></span><span className="bi-pie-four bi-pie-fill"></span></div></div>
                                <div className="pck-info al-card-bg radius-6 p-3 pt-4">
                                    <div className="mb-3"><span className="d-block font-16 font-weight-bold">Contract ID <span className="d-block font-weight-light text-info">000001</span></span></div>
                                    <div className=""><span className="d-block font-16 font-weight-bold">Date <span className="d-block font-weight-light text-info">28.01.2022</span></span></div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="pck-wrap mr-3">
                                <div className="d-flex mb-n3">
                                    <span className="text-light font-20 font-weight-bold al-card wh-60 d-flex align-items-center justify-content-center radius-100">2X</span>
                                    <span className="text-info font-20 al-card wh-60 d-flex align-items-center justify-content-center radius-100 ml-2">50%</span>
                                </div>
                                <div className="bi-pie-circle position-absolute"><div className="bi-pie-inner">
                                    <span className="bi-pie-one"></span> <span className="bi-pie-two"></span><span className="bi-pie-three bi-pie-fill"></span><span className="bi-pie-four bi-pie-fill"></span></div></div>
                                <div className="pck-info al-card-bg radius-6 p-3 pt-4">
                                    <div className="mb-3"><span className="d-block font-16 font-weight-bold">Contract ID <span className="d-block font-weight-light text-info">000001</span></span></div>
                                    <div className=""><span className="d-block font-16 font-weight-bold">Date <span className="d-block font-weight-light text-info">28.01.2022</span></span></div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="pck-wrap ml-3">
                                <div className="d-flex mb-n3">
                                    <span className="text-light font-20 font-weight-bold al-card wh-60 d-flex align-items-center justify-content-center radius-100">2X</span>
                                    <span className="font-20 al-card wh-60 d-flex align-items-center justify-content-center radius-100 ml-2">100%</span>
                                </div>
                                <div className="bi-pie-circle position-absolute disabled"><div className="bi-pie-inner">
                                    <span className="bi-pie-one bi-pie-fill"></span> <span className="bi-pie-two bi-pie-fill"></span><span className="bi-pie-three bi-pie-fill"></span><span className="bi-pie-four bi-pie-fill"></span></div></div>
                                <div className="pck-info al-card-bg radius-6 p-3 pt-4">
                                    <div className="mb-3"><span className="d-block font-16 font-weight-bold">Contract ID <span className="d-block font-weight-light">000001</span></span></div>
                                    <div className=""><span className="d-block font-16 font-weight-bold">Date <span className="d-block font-weight-light">28.01.2022</span></span></div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="pck-wrap mr-3">
                                <div className="d-flex mb-n3">
                                    <span className="text-light font-20 font-weight-bold al-card wh-60 d-flex align-items-center justify-content-center radius-100">2X</span>
                                    <span className="text-info font-20 al-card wh-60 d-flex align-items-center justify-content-center radius-100 ml-2">75%</span>
                                </div>
                                <div className="bi-pie-circle position-absolute"><div className="bi-pie-inner">
                                    <span className="bi-pie-one bi-pie-fill"></span> <span className="bi-pie-two bi-pie-fill"></span><span className="bi-pie-three bi-pie-fill"></span><span className="bi-pie-four bi-pie-fill"></span></div></div>
                                <div className="pck-info al-card-bg radius-6 p-3 pt-4">
                                    <div className="mb-3"><span className="d-block font-16 font-weight-bold">Contract ID <span className="d-block font-weight-light text-info">000001</span></span></div>
                                    <div className=""><span className="d-block font-16 font-weight-bold">Date <span className="d-block font-weight-light text-info">28.01.2022</span></span></div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="pck-wrap ml-3">
                                <div className="d-flex mb-n3">
                                    <span className="text-light font-20 font-weight-bold al-card wh-60 d-flex align-items-center justify-content-center radius-100">2X</span>
                                    <span className="text-info font-20 al-card wh-60 d-flex align-items-center justify-content-center radius-100 ml-2">75%</span>
                                </div>
                                <div className="bi-pie-circle position-absolute"><div className="bi-pie-inner">
                                    <span className="bi-pie-one bi-pie-fill"></span> <span className="bi-pie-two"></span><span className="bi-pie-three bi-pie-fill"></span><span className="bi-pie-four bi-pie-fill"></span></div></div>
                                <div className="pck-info al-card-bg radius-6 p-3 pt-4">
                                    <div className="mb-3"><span className="d-block font-16 font-weight-bold">Contract ID <span className="d-block font-weight-light text-info">000001</span></span></div>
                                    <div className=""><span className="d-block font-16 font-weight-bold">Date <span className="d-block font-weight-light text-info">28.01.2022</span></span></div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="pck-wrap mr-3">
                                <div className="d-flex mb-n3">
                                    <span className="text-light font-20 font-weight-bold al-card wh-60 d-flex align-items-center justify-content-center radius-100">2X</span>
                                    <span className="text-info font-20 al-card wh-60 d-flex align-items-center justify-content-center radius-100 ml-2">50%</span>
                                </div>
                                <div className="bi-pie-circle position-absolute"><div className="bi-pie-inner">
                                    <span className="bi-pie-one"></span> <span className="bi-pie-two"></span><span className="bi-pie-three bi-pie-fill"></span><span className="bi-pie-four bi-pie-fill"></span></div></div>
                                <div className="pck-info al-card-bg radius-6 p-3 pt-4">
                                    <div className="mb-3"><span className="d-block font-16 font-weight-bold">Contract ID <span className="d-block font-weight-light text-info">000001</span></span></div>
                                    <div className=""><span className="d-block font-16 font-weight-bold">Date <span className="d-block font-weight-light text-info">28.01.2022</span></span></div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="pck-wrap ml-3">
                                <div className="d-flex mb-n3">
                                    <span className="text-light font-20 font-weight-bold al-card wh-60 d-flex align-items-center justify-content-center radius-100">2X</span>
                                    <span className="font-20 al-card wh-60 d-flex align-items-center justify-content-center radius-100 ml-2">100%</span>
                                </div>
                                <div className="bi-pie-circle position-absolute disabled"><div className="bi-pie-inner">
                                    <span className="bi-pie-one bi-pie-fill"></span> <span className="bi-pie-two bi-pie-fill"></span><span className="bi-pie-three bi-pie-fill"></span><span className="bi-pie-four bi-pie-fill"></span></div></div>
                                <div className="pck-info al-card-bg radius-6 p-3 pt-4">
                                    <div className="mb-3"><span className="d-block font-16 font-weight-bold">Contract ID <span className="d-block font-weight-light">000001</span></span></div>
                                    <div className=""><span className="d-block font-16 font-weight-bold">Date <span className="d-block font-weight-light">28.01.2022</span></span></div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="pck-wrap mr-3">
                                <div className="d-flex mb-n3">
                                    <span className="text-light font-20 font-weight-bold al-card wh-60 d-flex align-items-center justify-content-center radius-100">2X</span>
                                    <span className="text-info font-20 al-card wh-60 d-flex align-items-center justify-content-center radius-100 ml-2">75%</span>
                                </div>
                                <div className="bi-pie-circle position-absolute"><div className="bi-pie-inner">
                                    <span className="bi-pie-one bi-pie-fill"></span> <span className="bi-pie-two bi-pie-fill"></span><span className="bi-pie-three bi-pie-fill"></span><span className="bi-pie-four bi-pie-fill"></span></div></div>
                                <div className="pck-info al-card-bg radius-6 p-3 pt-4">
                                    <div className="mb-3"><span className="d-block font-16 font-weight-bold">Contract ID <span className="d-block font-weight-light text-info">000001</span></span></div>
                                    <div className=""><span className="d-block font-16 font-weight-bold">Date <span className="d-block font-weight-light text-info">28.01.2022</span></span></div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="pck-wrap ml-3">
                                <div className="d-flex mb-n3">
                                    <span className="text-light font-20 font-weight-bold al-card wh-60 d-flex align-items-center justify-content-center radius-100">2X</span>
                                    <span className="text-info font-20 al-card wh-60 d-flex align-items-center justify-content-center radius-100 ml-2">75%</span>
                                </div>
                                <div className="bi-pie-circle position-absolute"><div className="bi-pie-inner">
                                    <span className="bi-pie-one bi-pie-fill"></span> <span className="bi-pie-two"></span><span className="bi-pie-three bi-pie-fill"></span><span className="bi-pie-four bi-pie-fill"></span></div></div>
                                <div className="pck-info al-card-bg radius-6 p-3 pt-4">
                                    <div className="mb-3"><span className="d-block font-16 font-weight-bold">Contract ID <span className="d-block font-weight-light text-info">000001</span></span></div>
                                    <div className=""><span className="d-block font-16 font-weight-bold">Date <span className="d-block font-weight-light text-info">28.01.2022</span></span></div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="pck-wrap mr-3">
                                <div className="d-flex mb-n3">
                                    <span className="text-light font-20 font-weight-bold al-card wh-60 d-flex align-items-center justify-content-center radius-100">2X</span>
                                    <span className="text-info font-20 al-card wh-60 d-flex align-items-center justify-content-center radius-100 ml-2">50%</span>
                                </div>
                                <div className="bi-pie-circle position-absolute"><div className="bi-pie-inner">
                                    <span className="bi-pie-one"></span> <span className="bi-pie-two"></span><span className="bi-pie-three bi-pie-fill"></span><span className="bi-pie-four bi-pie-fill"></span></div></div>
                                <div className="pck-info al-card-bg radius-6 p-3 pt-4">
                                    <div className="mb-3"><span className="d-block font-16 font-weight-bold">Contract ID <span className="d-block font-weight-light text-info">000001</span></span></div>
                                    <div className=""><span className="d-block font-16 font-weight-bold">Date <span className="d-block font-weight-light text-info">28.01.2022</span></span></div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="pck-wrap ml-3">
                                <div className="d-flex mb-n3">
                                    <span className="text-light font-20 font-weight-bold al-card wh-60 d-flex align-items-center justify-content-center radius-100">2X</span>
                                    <span className="font-20 al-card wh-60 d-flex align-items-center justify-content-center radius-100 ml-2">100%</span>
                                </div>
                                <div className="bi-pie-circle position-absolute disabled"><div className="bi-pie-inner">
                                    <span className="bi-pie-one bi-pie-fill"></span> <span className="bi-pie-two bi-pie-fill"></span><span className="bi-pie-three bi-pie-fill"></span><span className="bi-pie-four bi-pie-fill"></span></div></div>
                                <div className="pck-info al-card-bg radius-6 p-3 pt-4">
                                    <div className="mb-3"><span className="d-block font-16 font-weight-bold">Contract ID <span className="d-block font-weight-light">000001</span></span></div>
                                    <div className=""><span className="d-block font-16 font-weight-bold">Date <span className="d-block font-weight-light">28.01.2022</span></span></div>

                                </div>
                            </div>
                        </div>
                        

                    </div>
                </div>
            </div>
        </div>

        </div>
    )
}

export default Packages;
