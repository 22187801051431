import React from 'react';

import {BrowserRouter as Router, Route, Routes,} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/custom.css";
import Dashboard from "./Dashboard/Dashboard";
import Genealogy from "./Genealogy/Genealogy";
import Header from "./Components/Header";
import LeftSidebar from "./Components/LeftSidebar";
import RightSidebar from "./Components/RightSidebar";
import Promotions from "./Promotions/Promotions";
import Information from "./Information/Information";
import ConnectWallet from "./ConnectWallet/ConnectWallet";
import Packages from "./Packages/Packages";


const App = () => {

    return (<Router basename='/'>
        <Header />
        <LeftSidebar />
        <RightSidebar />
        <Routes>
            <Route path='/' element={<Dashboard/>}/>
            <Route path='/dashboard' element={<Dashboard/>}/>
            <Route path='/genealogy' element={<Genealogy/>}/>
            <Route path='/promotions' element={<Promotions/>}/>
            <Route path='/information' element={<Information/>}/>
            <Route path='/connect_wallet' element={<ConnectWallet/>}/>
            <Route path='/packages' element={<Packages/>}/>





        </Routes>
    </Router>)
}


export default App;
